import {
    i_button,
    i_buttonOptions,
    i_checkboxOptions,
    i_colorPickerOptions,
    i_container, i_data_entry_preview,
    i_desc,
    i_divider,
    i_fileInput,
    i_fileInputOptions,
    i_header,
    i_input,
    i_inputOptions,
    i_itemOptions,
    i_jsonArea,
    i_jsonAreaOptions,
    i_pickerOptions,
    i_progressSteps,
    i_progressStepsOptions,
    i_select,
    i_selectOptions,
    i_stepper,
    i_tabs,
    i_textArea,
    i_textAreaOptions,
    i_textEditorOptions,
    t_item
} from "@/types/interfaces/dialog.interfaces";
import {ITEM, PROGRESS_STATES} from "@/types/enums/dialog.enums";
import {i_text} from "@/types/interfaces/structure.interfaces";
import {Ref} from "vue";
import {Base} from "@/Base";
import {IRestriction} from "@/types/interfaces/dto.interfaces";

class ContainerBuilder extends Base {
    private container: i_container;

    constructor() {
        super(ContainerBuilder.name);
        this.container = {
            type: ITEM.CONTAINER,
            value: {
                items: [],
                horizontal: false
            }
        };
    }

    public setHorizontal(): ContainerBuilder {
        this.container.value.horizontal = true;
        return this;
    }

    public setOptions(options: i_itemOptions): ContainerBuilder {
        this.container.value.options = options;
        return this;
    }

    public addDesc(text: i_text, options?: i_itemOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.DESC,
            value: {
                text,
                options
            }
        } as i_desc);
        return this;
    }

    public addInput(label: i_text, modelValue: Ref<any>, options?: i_inputOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.INPUT,
            value: {
                label,
                modelValue,
                options
            }
        } as i_input);
        return this;
    }

    public addFileInput(label: i_text, modelValue: Ref<any>, options?: i_fileInputOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.FILE_INPUT,
            value: {
                label,
                modelValue,
                options
            }
        } as i_fileInput);
        return this;
    }

    public addProgressSteps(labels: Ref<i_text[]>, states: Ref<PROGRESS_STATES[]>, options?: i_progressStepsOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.PROGRESS_STEPS,
            value: {
                labels,
                states,
                options
            }
        } as i_progressSteps);
        return this;
    }

    public addSelect(label: i_text, modelValue: Ref<any>, items: Ref<string[] | { title: string, value: any }[]>, options?: i_selectOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.SELECT,
            value: {
                label,
                modelValue,
                items,
                options
            }
        } as i_select);
        return this;
    }

    public addJsonArea(label: i_text, modelValue: Ref<any>, options?: i_jsonAreaOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.JSON_AREA,
            value: {
                label,
                modelValue,
                options
            }
        } as i_jsonArea);
        return this;
    }

    public addTextArea(label: i_text, modelValue: Ref<any>, options?: i_textAreaOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.TEXT_AREA,
            value: {
                label,
                modelValue,
                options
            }
        } as i_textArea);
        return this;
    }

    public addHeader(title: i_text, options?: i_itemOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.HEADER,
            value: {
                title,
                options
            }
        } as i_header);
        return this;
    }

    public addDivider(title?: i_text, options?: i_itemOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.DIVIDER,
            value: {
                title,
                options
            }
        } as i_divider);
        return this;
    }

    public addButton(title: i_text, onClick: Function, options?: i_buttonOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.BUTTON,
            value: {
                title,
                onClick,
                options
            }
        } as i_button);
        return this;
    }

    public addIconPicker(modelValue: Ref<string | undefined>, options?: i_pickerOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.ICON_PICKER,
            value: {
                modelValue,
                options
            }
        })
        return this;
    }

    public addColorPicker(modelValue: Ref<string | undefined | null>, options?: i_colorPickerOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.COLOR_PICKER,
            value: {
                modelValue,
                options
            }
        })
        return this;
    }

    public addCheckbox(label: i_text, modelValue: Ref<boolean | undefined>, options?: i_checkboxOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.CHECKBOX,
            value: {
                modelValue,
                label,
                options
            }
        })
        return this;
    }

    public addJsonPicker(jsonData: Ref<any>, onClick: Function, options?: i_pickerOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.JSON_PICKER,
            value: {
                jsonData,
                onClick,
                options
            }
        })
        return this;
    }

    public addTextEditor(label: i_text, content: Ref<string | undefined>, options?: i_textEditorOptions): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.TEXT_EDITOR,
            value: {
                label,
                content,
                options
            }
        })
        return this;
    }

    public addPreviewButton(
        name: Ref<i_text | undefined>,
        color: Ref<string | undefined>,
        icon: Ref<string | undefined>,
        info: Ref<i_text | undefined>,
        sourceUrl: Ref<string | undefined>,
        permissions: Ref<string[] | undefined>,
        options?: i_itemOptions
    ): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.BUTTON_PREVIEW,
            value: {
                name,
                color,
                icon,
                info,
                sourceUrl,
                permissions,
                options
            }
        })
        return this;
    }

    public addPreviewMarker(
        icon: Ref<string | undefined>,
        iconColor: Ref<string | undefined | null>,
        color: Ref<string | undefined>
    ): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.MARKER_PREVIEW,
            value: {
                icon,
                iconColor,
                color
            }
        })
        return this;
    }

    public addPreviewContext(
        title: Ref<i_text | undefined>,
        text: Ref<i_text | undefined>,
        icon: Ref<string | undefined>,
        color: Ref<string | undefined>
    ): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.CONTEXT_PREVIEW,
            value: {
                title,
                text,
                icon,
                color
            }
        })
        return this;
    }

    public addDataEntryPreview(
        name: Ref<i_text | undefined>,
        color: Ref<string | undefined>,
        icon: Ref<string | undefined>,
        info: Ref<i_text | undefined>,
        sourceUrl: Ref<string | undefined>,
        restrictions: Ref<IRestriction | undefined>,
        options?: i_itemOptions
    ): ContainerBuilder {
        this.container.value.items.push({
            type: ITEM.DATA_ENTRY_PREVIEW,
            value: {
                name,
                color,
                icon,
                info,
                sourceUrl,
                restrictions,
                options
            }
        });
        return this;
    }

    public addContainer(container: i_container): ContainerBuilder {
        this.container.value.items.push(container);
        return this;
    }

    public addContainers(containers: i_container[]) {
        for (const container of containers) {
            this.addContainer(container);
        }
        return this;
    }

    public addTabs(tabs: i_tabs): ContainerBuilder {
        this.container.value.items.push(tabs);
        return this;
    }

    public addStepper(stepper: i_stepper): ContainerBuilder {
        this.container.value.items.push(stepper);
        return this;
    }

    public build(): i_container {
        return this.container;
    }
}

export const buildContainer = () => {
    return new ContainerBuilder();
}
