export enum ContextTypes {
    TEXT = "TEXT",
    TABLE = "TABLE",
    CONTAINER = "CONTAINER"
}

export enum Sorting {
    UP = "UP",
    DOWN = "DOWN"
}
