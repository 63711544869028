import {
        IAggregate,
        IAuthApiKey,
        IAuthBearer, ICase,
        ICategoryDto, IConditional, IContainer,
        ICreateCategoryDto,
        ICreateDataDto,
        ICreateEntryDto,
        ICreateGroupDto,
        ICredentials,
        IDataDto,
        IDownload,
        IEntryDto,
        IExchangeCategoryDto,
        IExchangeDataDto,
        IExchangeEntryDto,
        IExchangeGroupDto, IFormattingContainer,
        IFormattingTable,
        IFormattingText, IFrom, IGeoJsonGenericGeometry,
        IGeoJsonLineStringGeometry,
        IGeoJsonPointGeometry,
        IGeoJsonPolygonGeometry, IGetIsochrone, IGetIsochroneOptions,
        IGroupDto, IIsochroneOptions,
        IJoin,
        IRequest,
        IRestriction,
        ISource,
        ISourceTemplate,
        IStatic, ISwitch, ITable,
        IText,
        TAuth, TFormatting, TFunctions,
        TGeojsonGeometry,
        TRequest
} from "../interfaces/dto.interfaces";
import {GEOJSON_TYPES} from "../enums/geojson.enums";
import {ContextTypes, Sorting} from "../enums/formatting.enums";
import {BadRequest} from "http-errors";
import {MOT} from "../enums/mot.enums";
import {FUNCTIONS} from "../enums/function.enums";

export const isText = (obj: any): obj is IText =>
    obj && typeof obj === "object";

export const isRestrictions = (obj: any): obj is IRestriction =>
    obj &&
    typeof obj === 'object' &&
    (obj.read === undefined || (Array.isArray(obj.read) && obj.read.every((item: any) => typeof item === 'string'))) &&
    Array.isArray(obj.create) && obj.create.every((item: any) => typeof item === 'string') &&
    Array.isArray(obj.delete) && obj.delete.every((item: any) => typeof item === 'string') &&
    Array.isArray(obj.update) && obj.update.every((item: any) => typeof item === 'string');

export const isDataDto = (obj: any): obj is IDataDto =>
    obj &&
    typeof obj === "object" &&
    typeof obj.endpoint === "string" &&
    typeof obj.useCsrf === "boolean" &&
    typeof obj.secure === "boolean" &&
    isRestrictions(obj.restrictions);

export const isGroupDto = (obj: any): obj is IGroupDto =>
    obj &&
    typeof obj === "object" &&
    typeof obj.id === "string" &&
    typeof obj.idx === "number" &&
    (obj.restrictions === undefined || isRestrictions(obj.restrictions)) &&
    typeof obj.name === "object" &&
    Object.values(obj.name).every((value) => typeof value === "string");

export const isCategoryDto = (obj: any): obj is ICategoryDto =>
    obj &&
    typeof obj === "object" &&
    typeof obj.id === "string" &&
    typeof obj.idx === "number" &&
    (obj.restrictions === undefined || isRestrictions(obj.restrictions)) &&
    typeof obj.name === "object" &&
    Object.values(obj.name).every((value) => typeof value === "string");

export const isEntryDto = (obj: any): obj is IEntryDto =>
    obj &&
    typeof obj === 'object' &&
    typeof obj.id === 'string' &&
    typeof obj.idx === 'number' &&
    isText(obj.name) &&
    typeof obj.icon === 'string' &&
    typeof obj.color === 'string' &&
    (obj.iconColor === undefined || typeof obj.iconColor === 'string') &&
    (obj.description === undefined || isText(obj.description)) &&
    (obj.link === undefined || typeof obj.link === 'string') &&
    (obj.download === undefined || isRequest(obj.download) || isDownload(obj.download) || typeof obj.download === 'string') &&
    (obj.restrictions === undefined || isRestrictions(obj.restrictions)) &&
    Array.isArray(obj.sources) &&
    obj.sources.every((item: any) => isSource(item));

export const isSource = (obj: any): obj is ISource =>
    obj &&
    typeof obj === 'object' &&
    isSourceTemplate(obj.template) &&
    isTRequest(obj.location) &&
    isTRequest(obj.context) &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number") &&
    isFormatting(obj.formatting)

export const isSourceTemplate = (obj: any): obj is ISourceTemplate =>
    obj &&
    typeof obj === 'object' &&
    isText(obj.name) &&
    (obj.icon === undefined || typeof obj.icon === 'string' || isSwitch(obj.icon) || isConditional(obj.icon)) &&
    (obj.color === undefined || typeof obj.color === 'string' || isSwitch(obj.color) || isConditional(obj.color)) &&
    (obj.iconColor === undefined || typeof obj.iconColor === 'string' || isSwitch(obj.iconColor) || isConditional(obj.iconColor)) &&
    (Object.values(GEOJSON_TYPES).includes(obj.type.toUpperCase())) &&
    (typeof obj.coordinates === 'string' ||
        (Array.isArray(obj.coordinates) && obj.coordinates.length === 2)) &&
    (obj.swapCoordinates === undefined || typeof obj.swapCoordinates === 'boolean') &&
    (obj.markCenter === undefined || typeof obj.markCenter === 'boolean');

export const isGeoJsonPointGeometry = (obj: any): obj is IGeoJsonPointGeometry =>
    obj &&
    typeof obj === 'object' &&
    obj.type === 'POINT' &&
    (typeof obj.coordinates === 'string' ||
        (Array.isArray(obj.coordinates) &&
            obj.coordinates.length === 2 &&
            (typeof obj.coordinates[0] === 'string' || typeof obj.coordinates[0] === 'number') &&
            (typeof obj.coordinates[1] === 'string' || typeof obj.coordinates[1] === 'number')));

export const isGeoJsonLineStringGeometry = (obj: any): obj is IGeoJsonLineStringGeometry =>
    obj &&
    typeof obj === 'object' &&
    obj.type === 'LINESTRING' &&
    Array.isArray(obj.coordinates) &&
    obj.coordinates.every(
        (coord: any) =>
            Array.isArray(coord) &&
            coord.length === 2 &&
            (typeof coord[0] === 'string' || typeof coord[0] === 'number') &&
            (typeof coord[1] === 'string' || typeof coord[1] === 'number')
    );

export const isGeoJsonPolygonGeometry = (obj: any): obj is IGeoJsonPolygonGeometry =>
    obj &&
    typeof obj === 'object' &&
    obj.type === 'POLYGON' &&
    Array.isArray(obj.coordinates) &&
    obj.coordinates.every((ring: any) =>
        Array.isArray(ring) &&
        ring.every(
            (coord: any) =>
                Array.isArray(coord) &&
                coord.length === 2 &&
                (typeof coord[0] === 'string' || typeof coord[0] === 'number') &&
                (typeof coord[1] === 'string' || typeof coord[1] === 'number')
        )
    );

export const isGeoJsonGenericGeometry = (obj: any): obj is IGeoJsonGenericGeometry =>
    obj &&
    typeof obj === "object" &&
    typeof obj.type === "string" &&
    typeof obj.coordinates === "string"

export const isGeojsonGeometry = (obj: any): obj is TGeojsonGeometry =>
    isGeoJsonPointGeometry(obj) || isGeoJsonLineStringGeometry(obj) || isGeoJsonPolygonGeometry(obj) || isGeoJsonGenericGeometry(obj);

export const isDownload = (obj: any): obj is IDownload =>
    obj &&
    typeof obj === 'object' &&
    (obj.request === undefined || isTRequest(obj.request)) &&
    (obj.swapCoordinates === undefined || typeof obj.swapCoordinates === 'boolean') &&
    obj.geojsonTemplate &&
    typeof obj.geojsonTemplate === 'object' &&
    isGeojsonGeometry(obj.geojsonTemplate.geometry) &&
    typeof obj.geojsonTemplate.properties !== 'undefined';


// export const isExchangeEntryDto = (obj: any): obj is IExchangeEntryDto =>
//     isEntryDto({ ...obj, id: "dummy", idx: 0 }) &&
//     obj.id === undefined &&
//     (obj.idx === undefined || typeof obj.idx === "number");
//
// export const isExchangeCategoryDto = (obj: any): obj is IExchangeCategoryDto =>
//     isCategoryDto({ ...obj, id: "dummy", idx: 0 }) &&
//     obj.id === undefined &&
//     (obj.idx === undefined || typeof obj.idx === "number") &&
//     Array.isArray((obj as any).entries) &&
//     (obj as any).entries.every(isExchangeEntryDto);
//
// export const isExchangeGroupDto = (obj: any): obj is IExchangeGroupDto =>
//     isGroupDto({ ...obj, id: "dummy", idx: 0 }) &&
//     obj.id === undefined &&
//     (obj.idx === undefined || typeof obj.idx === "number") &&
//     Array.isArray((obj as any).categories) &&
//     (obj as any).categories.every(isExchangeCategoryDto);
//
// export const isExchangeDataDto = (obj: any): obj is IExchangeDataDto =>
//     isDataDto(obj) &&
//     Array.isArray((obj as any).groups) &&
//     (obj as any).groups.every(isExchangeGroupDto);

export const isExchangeSource = (obj: any): obj is ISource => {
        if (!obj || typeof obj !== 'object') {
                throw new BadRequest(`Validation failed for Source: obj must be an object, but got ${typeof obj}`);
        }
        if (!isSourceTemplate(obj.template)) {
                throw new BadRequest(
                    `Validation failed for Source: template must be a valid SourceTemplate, but got ${JSON.stringify(obj.template)}`
                );
        }
        if (!isTRequest(obj.location)) {
                throw new BadRequest(
                    `Validation failed for Source: location must be a valid TRequest, but got ${JSON.stringify(obj.location)}`
                );
        }
        if (!isTRequest(obj.context)) {
                throw new BadRequest(
                    `Validation failed for Source: context must be a valid TRequest, but got ${JSON.stringify(obj.context)}`
                );
        }
        if (obj.refreshRate !== undefined && typeof obj.refreshRate !== "number") {
                throw new BadRequest(
                    `Validation failed for Source: refreshRate must be a number or undefined, but got ${typeof obj.refreshRate}`
                );
        }
        if (!isFormatting(obj.formatting)) {
                throw new BadRequest(
                    `Validation failed for Source: formatting must be valid Formatting, but got ${JSON.stringify(obj.formatting)}`
                );
        }
        return true;
};

export const isExchangeFunction = (obj: any): obj is TFunctions => {
        if (!obj || typeof obj !== 'object') {
                throw new BadRequest(`Validation failed for Function: Must be an object, but got ${typeof obj}`);
        }
        if (!Object.values(FUNCTIONS).includes(obj.type)) {
                throw new BadRequest(`Validation failed for Function: Attribute 'type' must be a valid function type (${Object.values(FUNCTIONS).join(", ")}), but received '${obj.type}'`);
        }
        if (!isText(obj.name)) {
                throw new BadRequest(`Validation failed for Function: Attribute 'name' must be valid text, but got ${JSON.stringify(obj.name)}`);
        }
        if (!obj.content || typeof obj.content !== 'object') {
                throw new BadRequest(`Validation failed for Function: Attribute 'content' must be a valid object, but got ${typeof obj.content}`);
        }
        return true;
}


export const isExchangeEntryDto = (obj: any): obj is IExchangeEntryDto => {
        if (!obj || typeof obj !== 'object') {
                throw new BadRequest(`Validation failed for EntryDto: obj must be an object, but got ${typeof obj}`);
        }
        if (obj.idx !== undefined && typeof obj.idx !== 'number') {
                throw new BadRequest(`Validation failed for EntryDto: idx must be a number, but got ${typeof obj.idx}`);
        }
        if (!isText(obj.name)) {
                throw new BadRequest(`Validation failed for EntryDto: name must be valid text, but got ${JSON.stringify(obj.name)}`);
        }
        if (typeof obj.icon !== 'string') {
                throw new BadRequest(`Validation failed for EntryDto: icon must be a string, but got ${typeof obj.icon}`);
        }
        if (typeof obj.color !== 'string') {
                throw new BadRequest(`Validation failed for EntryDto: color must be a string, but got ${typeof obj.color}`);
        }
        if (obj.iconColor !== undefined && typeof obj.iconColor !== 'string') {
                throw new BadRequest(`Validation failed for EntryDto: iconColor must be a string or undefined, but got ${typeof obj.iconColor}`);
        }
        if (obj.description !== undefined && !isText(obj.description)) {
                throw new BadRequest(`Validation failed for EntryDto: description must be valid text or undefined, but got ${JSON.stringify(obj.description)}`);
        }
        if (obj.link !== undefined && typeof obj.link !== 'string') {
                throw new BadRequest(`Validation failed for EntryDto: link must be a string or undefined, but got ${typeof obj.link}`);
        }
        if (
            obj.download !== undefined &&
            !(
                isRequest(obj.download) ||
                isDownload(obj.download) ||
                typeof obj.download === 'string'
            )
        ) {
                throw new BadRequest(
                    `Validation failed for EntryDto: download must be a Request, Download, string, or undefined, but got ${typeof obj.download}`
                );
        }
        if (obj.restrictions !== undefined && !isRestrictions(obj.restrictions)) {
                throw new BadRequest(
                    `Validation failed for EntryDto: restrictions must be valid Restrictions or undefined, but got ${JSON.stringify(obj.restrictions)}`
                );
        }
        if (!Array.isArray(obj.sources)) {
                throw new BadRequest(`Validation failed for EntryDto: sources must be an array, but got ${typeof obj.sources}`);
        }
        obj.sources.every((item: any) => isExchangeSource(item));
        if (!Array.isArray(obj.functions)) {
                throw new BadRequest(`Validation failed for EntryDto: functions must be an array, but got ${typeof obj.sources}`);
        }
        obj.functions.every((item: any) => isExchangeFunction(item));
        if (obj.id !== undefined) {
                throw new BadRequest(`Validation failed for ExchangeEntryDto: id must be undefined, but got ${obj.id}`);
        }
        if (obj.idx !== undefined && typeof obj.idx !== "number") {
                throw new BadRequest(`Validation failed for ExchangeEntryDto: idx must be a number, but got ${obj.idx}`);
        }
        return true;
};

export const isExchangeCategoryDto = (obj: any): obj is IExchangeCategoryDto => {
        if (!isCategoryDto({ ...obj, id: "dummy", idx: 0 })) {
                throw new BadRequest(`Validation failed for ExchangeCategoryDto: obj is not a valid CategoryDto: ${JSON.stringify({ ...obj, entries: "[...]" })}`);
        }
        if (obj.id !== undefined) {
                throw new BadRequest(`Validation failed for ExchangeCategoryDto: id must be undefined, but got ${obj.id}`);
        }
        if (obj.idx !== undefined && typeof obj.idx !== "number") {
                throw new BadRequest(`Validation failed for ExchangeCategoryDto: idx must be a number, but got ${obj.idx}`);
        }
        if (!Array.isArray((obj as any).entries)) {
                throw new BadRequest(`Validation failed for ExchangeCategoryDto: entries must be an array, but got ${typeof (obj as any).entries}: ${JSON.stringify(obj)}`);
        }
        if (!(obj as any).entries.every(isExchangeEntryDto)) {
                throw new BadRequest(`Validation failed for ExchangeCategoryDto: some entries are not valid ExchangeEntryDto`);
        }
        return true;
};

export const isExchangeGroupDto = (obj: any): obj is IExchangeGroupDto => {
        if (!isGroupDto({ ...obj, id: "dummy", idx: 0 })) {
                throw new BadRequest(`Validation failed for ExchangeGroupDto: obj is not a valid GroupDto: ${JSON.stringify({ ...obj, categories: "[...]" })}`);
        }
        if (obj.id !== undefined) {
                throw new BadRequest(`Validation failed for ExchangeGroupDto: id must be undefined, but got ${obj.id}`);
        }
        if (obj.idx !== undefined && typeof obj.idx !== "number") {
                throw new BadRequest(`Validation failed for ExchangeGroupDto: idx must be a number, but got ${obj.idx}`);
        }
        if (!Array.isArray((obj as any).categories)) {
                throw new BadRequest(`Validation failed for ExchangeGroupDto: categories must be an array, but got ${typeof (obj as any).categories}`);
        }
        if (!(obj as any).categories.every(isExchangeCategoryDto)) {
                throw new BadRequest(`Validation failed for ExchangeGroupDto: some categories are not valid ExchangeCategoryDto`);
        }
        return true;
};

export const isExchangeDataDto = (obj: any): obj is IExchangeDataDto => {
        if (!isDataDto(obj)) {
                throw new BadRequest(`Validation failed for ExchangeDataDto: obj is not a valid DataDto: ${JSON.stringify({ ...obj, groups: "[...]" })}`);
        }
        if (!Array.isArray((obj as any).groups)) {
                throw new BadRequest(`Validation failed for ExchangeDataDto: groups must be an array, but got ${typeof (obj as any).groups}`);
        }
        if (!(obj as any).groups.every(isExchangeGroupDto)) {
                throw new BadRequest(`Validation failed for ExchangeDataDto: some groups are not valid ExchangeGroupDto`);
        }
        return true;
};


export const isCredentials = (obj: any): obj is ICredentials =>
    obj &&
    typeof obj === 'object' &&
    typeof obj.username === 'string' &&
    typeof obj.password === 'string';

export const isAuthApiKey = (obj: any): obj is IAuthApiKey =>
    obj &&
    typeof obj === 'object' &&
    obj.type === 'API_KEY' &&
    typeof obj.key === 'string' &&
    typeof obj.header === 'string';

export const isAuthBearer = (obj: any): obj is IAuthBearer =>
    obj &&
    typeof obj === 'object' &&
    obj.type === 'BEARER' &&
    isCredentials(obj.credentials) &&
    typeof obj.url === 'string' &&
    (obj.header === undefined || typeof obj.header === 'string') &&
    (obj.tokenAttribute === undefined || typeof obj.tokenAttribute === 'string') &&
    (obj.refreshTokenAttribute === undefined || typeof obj.refreshTokenAttribute === 'string') &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === 'number');

export const isTAuth = (obj: any): obj is TAuth =>
    isAuthApiKey(obj) || isAuthBearer(obj);

export const isRequest = (obj: any): obj is IRequest =>
    obj &&
    typeof obj === 'object' &&
    (obj.type === 'HTTP' || obj.type === 'MQTT') &&
    typeof obj.url === 'string' &&
    (obj.auth === undefined || isTAuth(obj.auth)) &&
    (obj.dataRoot === undefined || typeof obj.dataRoot === 'string') &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isStatic = (obj: any): obj is IStatic =>
    obj &&
    typeof obj === 'object' &&
    (obj.clientSide === undefined || typeof obj.clientSide === "boolean") &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number") &&
    'data' in obj;

export const isJoin = (obj: any): obj is IJoin =>
    obj &&
    typeof obj === 'object' &&
    Array.isArray(obj.on) &&
    obj.on.every((item: any) => typeof item === 'string') &&
    Array.isArray(obj.join) &&
    obj.join.every(isTRequest) &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isAggregate = (obj: any): obj is IAggregate =>
    obj &&
    typeof obj === 'object' &&
    typeof obj.on === "string" &&
    isTRequest(obj.aggregate) &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isFrom = (obj: any): obj is IFrom =>
    obj &&
    typeof obj === 'object' &&
    typeof obj.on === "string" &&
    isTRequest(obj.from) &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isTRequest = (obj: any): obj is TRequest =>
    isRequest(obj) || isStatic(obj) || isJoin(obj) || isAggregate(obj) || isFrom(obj);

export const isCreateDataDto = (obj: any): obj is ICreateDataDto =>
    isDataDto(obj);

export const isCreateGroupDto = (obj: any): obj is ICreateGroupDto =>
    obj &&
    typeof obj === "object" &&
    isGroupDto({ ...obj, id: "dummy-id" }); // Add a placeholder for the omitted "id"

export const isCreateCategoryDto = (obj: any): obj is ICreateCategoryDto =>
    obj &&
    typeof obj === "object" &&
    isCategoryDto({ ...obj, id: "dummy-id" }); // Add a placeholder for the omitted "id"

export const isCreateEntryDto = (obj: any): obj is ICreateEntryDto =>
    obj &&
    typeof obj === "object" &&
    isEntryDto({ ...obj, id: "dummy-id" }); // Add a placeholder for the omitted "id"

// Guard for IFormattingText
export const isFormattingText = (obj: any): obj is IFormattingText =>
    obj &&
    typeof obj === "object" &&
    obj.type === ContextTypes.TEXT &&
    isText(obj.value);

// Guard for IFormattingTable
export const isFormattingTable = (obj: any): obj is IFormattingTable =>
    obj &&
    typeof obj === "object" &&
    obj.type === ContextTypes.TABLE &&
    isTable(obj.value);

// Guard for IFormattingContainer
export const isFormattingContainer = (obj: any): obj is IFormattingContainer =>
    obj &&
    typeof obj === "object" &&
    obj.type === ContextTypes.CONTAINER &&
    isContainer(obj.value);

// Guard for IContainer
export const isContainer = (obj: any): obj is IContainer =>
    obj &&
    typeof obj === "object" &&
    Array.isArray(obj.elements) &&
    obj.elements.every(isFormatting) && // Validate all elements in the array
    (obj.horizontal === undefined || typeof obj.horizontal === "boolean");

// Guard for ITable
export const isTable = (obj: any): obj is ITable =>
    obj &&
    typeof obj === "object" &&
    Array.isArray(obj.columns) &&
    obj.columns.every(
        (col: any) =>
            col &&
            typeof col === "object" &&
            isText(col.name) &&
            (col.sort === undefined || Object.values(Sorting).includes(col.sort)) &&
            (col.description === undefined || isText(col.description))
    ) &&
    (Array.isArray(obj.rows) &&
        obj.rows.every(
            (row: any) =>
                Array.isArray(row)
                    ? row.every((item) => typeof item === "string" || typeof item === "number")
                    : typeof row === "string"
        ));

// Type guard for TFormatting
export const isFormatting = (obj: any): obj is TFormatting =>
    isFormattingText(obj) || isFormattingTable(obj) || isFormattingContainer(obj);

export const isConditional = (obj: any): obj is IConditional => {
        return obj !== undefined &&
            typeof obj === 'object' && obj !== null &&
            'exp' in obj && typeof obj.exp === 'string' &&
            'true' in obj && (typeof obj.true === 'string' || typeof obj.true === 'number' || typeof obj.true === 'boolean' || isConditional(obj.true) || isSwitch(obj.true)) &&
            'false' in obj && (typeof obj.false === 'string' || typeof obj.false === 'number' || typeof obj.false === 'boolean' || isConditional(obj.false) || isSwitch(obj.false));
}

export const isSwitch = (obj: any): obj is ISwitch => {
        return obj !== undefined &&
            typeof obj === 'object' && obj !== null &&
            'default' in obj && (typeof obj.default === 'string' || typeof obj.default === 'number' || typeof obj.default === 'boolean' || isConditional(obj.default) || isSwitch(obj.default)) &&
            'cases' in obj && Array.isArray(obj.cases) && obj.cases.every(isCase);
}

export const isCase = (obj: any): obj is ICase => {
        return typeof obj === 'object' && obj !== null &&
            'exp' in obj && typeof obj.exp === 'string' &&
            'value' in obj && (typeof obj.value === 'string' || typeof obj.value === 'number' || typeof obj.value === 'boolean' || isConditional(obj.value) || isSwitch(obj.value))
}

export const isGetIsochroneOptions = (obj: any): obj is IGetIsochroneOptions =>
    obj &&
    typeof obj === 'object' &&
    (obj.delay === undefined || typeof obj.delay === 'number') &&
    (obj.timeSteps === undefined ||
        (Array.isArray(obj.timeSteps) && obj.timeSteps.every((step: any) => typeof step === 'number'))) &&
    (obj.modes === undefined || (Array.isArray(obj.modes) && obj.modes.every((mode: any) => typeof mode === 'string' && (Object.values(MOT) as string[]).includes(mode))));

export const isGetIsochrone = (obj: any): obj is IGetIsochrone => {
        if (!obj || typeof obj !== 'object') {
                throw new BadRequest(`Validation failed for IGetIsochrone: obj must be an object, but got ${typeof obj}`);
        }
        if (typeof obj.lat !== 'number') {
                throw new BadRequest(
                    `Validation failed for IGetIsochrone: lat must be a number, but got ${typeof obj.lat}`
                );
        }
        if (typeof obj.lon !== 'number') {
                throw new BadRequest(
                    `Validation failed for IGetIsochrone: lon must be a number, but got ${typeof obj.lon}`
                );
        }
        if (obj.options !== undefined && !isGetIsochroneOptions(obj.options)) {
                throw new BadRequest(
                    `Validation failed for IGetIsochrone: options must be a valid IIsochroneOptions or undefined, but got ${JSON.stringify(obj.options)}`
                );
        }
        return true;
};
