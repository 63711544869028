export enum LANGUAGE {
    ENGLISH = "en",
    GERMAN = "de"
}

export enum THEME {
    LIGHT = "light",
    DARK = "dark",
}

export enum SID {
    LANGUAGE = "zm-language",
    THEME = "zm-theme",
    DRAWER_STATE = "zm-drawer-state",
    DRAWER_SELECTION = "zm-drawer-selection",
    MAP_DATA = "zm-map-data",
    CONTEXT_STATE = "zm-context-state",
    EXPANSION_PANEL_STATE = "zm-expansion-panel-state",
    REPLACEMENT_MATRIX = "zm-replacement-matrix",
    //MAP_SETTINGS = "zm-map-settings",
    MARKER_BUTTON_STATE = "zm-marker-button-state",
    MARKER_FUNCTION_STATE = "zm-marker-function-state",
    MARKER_BUTTON_EXPANDED_STATES = "zm-marker-button-expanded-states",
    MAP_CONTEXT_SELECTION = "zm-map-context-selection",
    DIALOG_SCREEN_STATE = "zm-login-screen-state",
    CURRENT_POSITION = "zm-current-position",
    CURRENT_POSITION_WAS_SET = "zm-current-position-was-set",
    GRAFANA_URL_DARK = "zm-grafana-url-dark",
    GRAFANA_URL_LIGHT = "zm-grafana-url-light",
    // path for creating new data buttons and categories
    DATA_PATH = "zm-data-path",
    DATA_VALIDATOR = "zm-data-validator",
    // for custom sids
    PREFIX = "zm-"
}

export enum LID {
    PREFIX = "zm-lmap-",
    POSITION = "zm-lmap-position",
    ZOOM = "zm-lmap-zoom",
    MARKERS = "zm-lmap-markers",
    POLYLINES = "zm-lmap-polylines",
    CONTEXT_MARKER = "zm-context-marker",
    CONTEXT_DATA = "zm-context-data",
    CONTEXT_DATA_POPUP = "zm-context-data-popup",
    CONTEXT_POLL_ID = "zm-context-poll-id",
    CONTEXT_POPUP_POLL_ID = "zm-context-popup-poll-id",
    ROUTER_WAYPOINTS = "zm-router-waypoints",
    ROUTER_DATE = "zm-router-date",
    ROUTER_TIME = "zm-router-time",
    ROUTER_WAYPOINT_MARKER_PREFIX = "zm-lmap-router-waypoint-marker-",
    MY_POSITION_MARKER = "zm-my-position-marker",
    ROUTES = "zm-routes",
    CURRENT_ROUTE = "zm-current-route",
    MODES = "zm-modes-of-transport"
}

export enum DATA_WILDCARDS {
    // geo wildcards (latitude, longitude of current map center)
    ZM_LAT = "ZM_LAT",
    ZM_LNG = "ZM_LNG",
    // date wildcards
    ZM_YY = "ZM_YY",
    ZM_YYYY = "ZM_YYYY",
    ZM_MM = "ZM_MM",
    ZM_DD = "ZM_DD",
    ZM_M = "ZM_M",
    ZM_H = "ZM_H",
    ZM_S = "ZM_S",
    // weekdays (return 1 if it is the current day)
    ZM_MON = "ZM_MON",
    ZM_TUE = "ZM_TUE",
    ZM_WED = "ZM_WED",
    ZM_THU = "ZM_THU",
    ZM_FRI = "ZM_FRI",
    ZM_SAT = "ZM_SAT",
    ZM_SUN = "ZM_SUN",
    // parameterized
    ZM_CALC = "ZM_CALC",
    ZM_REF = "ZM_REF",
    ZM_EVAL = "ZM_EVAL",
    ZM_ROUND = "ZM_ROUND"
}

export enum DASHBOARD_COMP_TYPES {
    TEXT = "text",
    LINECHART = "linechart",
    BARCHART = "barchart",
    PIECHART = "piechart",
    DOUGHNUTCHART = "doughnutchart",
    SINGLE_VALUE = "singlevalue",
}

export enum HEADLINE_SIZE {
    MAIN = "main",
    SUB = "sub",
    SUBSUB = "subsub",
}

export enum MDI {
    DOTS = "mdi-dots-vertical",
    MENU = "mdi-menu",
    LOGIN = "mdi-login-variant",
    CHEVRON_UP = "mdi-chevron-up",
    CHEVRON_LEFT = "mdi-chevron-left",
    CHEVRON_RIGHT = "mdi-chevron-right",
    CHEVRON_DOWN = "mdi-chevron-down",
    TRANSLATE = "mdi-translate",
    THEME = "mdi-theme-light-dark",
    MAP = "mdi-map",
    VIEW_DASHBOARD_OUTLINE = "mdi-view-dashboard-variant-outline",
    CLOSE = "mdi-close",
    MARKER_REMOVE = "mdi-map-marker-remove-outline",
    MAGNIFY = "mdi-magnify",
    MARKER_OUTLINE = "mdi-map-marker-plus-outline",
    MARKER_START = "mdi-map-marker",
    MARKER_FLAG = "mdi-flag-checkered",
    MARKER_VIA = "mdi-map-marker-plus",
    INFO = "mdi-information-slab-circle-outline",
    HELP = "mdi-help",
    BUS = "mdi-bus",
    TRAM = "mdi-tram",
    TRAIN = "mdi-train",
    CAR = "mdi-car",
    BICYCLE = "mdi-bicycle",
    SCOOTER = "mdi-scooter",
    WALK = "mdi-walk",
    CIRCLE_BLANK = "mdi-checkbox-blank-circle-outline",
    ARROW_DOWN_OUTLINE = "mdi-arrow-down-thin-circle-outline",
}

export enum MOT {
    BUS = "zm-mot-bus",
    TRAM = "zm-mot-tram",
    RAIL = "zm-mot-rail",
    CAR = "zm-mot-car",
    BIKE = "zm-mot-bike",
    WALK = "zm-mot-walk",
    SCOOTER = "zm-mot-scooter",
    UNKNOWN = "zm-mot-unknown"
}

export enum MARKER_STATE {
    ACTIVE = "zm-marker-state-active",
    PENDING = "zm-marker-state-pending",
    INACTIVE = "zm-marker-state-inactive"
}

export enum DIALOG_TEMPLATES {
    LOGIN,
    LANGUAGE,
    ADD_DATA_ENTRY,
    DELETE_DATA_ENTRY,
    ADD_CATEGORY_ENTRY,
    GENERIC
}
