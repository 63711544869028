import {
    i_aggregate,
    i_join,
    i_from,
    t_operation,
    i_case,
    i_columnDef,
    i_conditional,
    i_categoryDto,
    i_entryContextDto,
    i_entryDto,
    i_entryInfoDto, i_dataPanelDto,
    i_fileSourceDto,
    i_formattingTable,
    i_formattingTableValue,
    i_formattingText,
    i_geojsonTemplateDto,
    i_geojsonTemplateGeometryDto,
    i_geojsonTemplateLineStringDto,
    i_geojsonTemplatePointDto, i_geojsonTemplatePolygonDto,
    i_mapMarker,
    i_mapPolyline,
    i_mapTemplate,
    i_request, i_routerPanelDto,
    i_sourceDto,
    i_switch,
    i_text,
    t_formatting, i_mapping, t_permission, i_validator
} from "../interfaces/structure.interfaces";
import {CONTEXT_TYPES, MAP_TEMPLATE, MAPPING_TYPE, PANEL_TYPES} from "../enums/general.enums";
import {GeoJsonTypes} from "@/types/enums/general.enums";

export const isText = (obj: any): obj is i_text => {
    return obj !== undefined && typeof obj === 'object' &&
        'en' in obj && 'de' in obj &&
        typeof obj.en === 'string' &&
        typeof obj.de === 'string';
};

export const isMapTemplate = (obj: any): obj is i_mapTemplate => {
    return obj !== undefined &&
        'type' in obj &&
        'name' in obj &&
        'coordinates' in obj && (typeof obj.coordinates === 'string' || Array.isArray(obj.coordinates)) &&
        (obj.color === undefined || (typeof obj.color === 'string' || isConditional(obj.color) || isSwitch(obj.color))) &&
        (obj.swapCoordinates === undefined || typeof obj.swapCoordinates === 'boolean') &&
        (obj.icon === undefined || (typeof obj.icon === 'string' || isConditional(obj.icon) || isSwitch(obj.icon))) &&
        (obj.iconColor === undefined || (typeof obj.iconColor === 'string' || isConditional(obj.iconColor) || isSwitch(obj.iconColor)));
}

export const isMapMarker = (obj: any): obj is i_mapMarker => {
    return (
        isMapTemplate(obj) &&
        typeof obj.type === 'string' &&
        obj.type.toUpperCase() === MAP_TEMPLATE.MARKER &&
        'coordinates' in obj &&
        (typeof obj.coordinates === 'string' ||
            (Array.isArray(obj.coordinates) &&
                obj.coordinates.length === 2 &&
                (typeof obj.coordinates[0] === 'string' || typeof obj.coordinates[0] === 'number') &&
                (typeof obj.coordinates[1] === 'string' || typeof obj.coordinates[1] === 'number')))
    );
}

export const isMapPolyline = (obj: any): obj is i_mapPolyline => {
    return (
        isMapTemplate(obj) &&
        typeof obj.type === 'string' &&
        obj.type.toUpperCase() === MAP_TEMPLATE.POLYLINE &&
        ((obj as i_mapPolyline).markCenter === undefined || typeof (obj as i_mapPolyline).markCenter === 'boolean') &&
        'coordinates' in obj &&
        (typeof obj.coordinates === 'string' ||
            (Array.isArray(obj.coordinates) &&
                ((obj.coordinates as any[]).every(coord => coord.length === 2 &&
                    (typeof coord[0] === 'number' || typeof coord[0] === 'string') &&
                    (typeof coord[1] === 'number' || typeof coord[1] === 'string')))
            )
        )
    );
};

export const isFormattingText = (obj: any): obj is i_formattingText => {
    return (
        obj !== undefined &&
        obj.type === CONTEXT_TYPES.TEXT &&
        (isText(obj.value) ||
            typeof obj.value === 'string' ||
            (Array.isArray(obj.value) && obj.value.every((item: any) => isText(item) || typeof item === 'string')) ||
            (Array.isArray(obj.value) &&
                obj.value.every((row: any) => Array.isArray(row) && row.every((item: any) => isText(item) || typeof item === 'string'))))
    );
};

export const isFormattingTable = (obj: any): obj is i_formattingTable => {
    return obj !== undefined &&
        'type' in obj &&
        typeof obj.type === 'string' &&
        obj.type.toUpperCase() === CONTEXT_TYPES.TABLE &&
        'value' in obj &&
        'columns' in obj.value &&
        Array.isArray(obj.value.columns) &&
        obj.value.columns.every(isColumnDef) &&
        'rows' in obj.value &&
        Array.isArray(obj.value.rows);
};

export const isFormattingTableValue = (obj: any): obj is i_formattingTableValue => {
    return obj !== undefined &&
        'columns' in obj &&
        Array.isArray(obj.columns) &&
        obj.columns.every(isColumnDef) &&
        'rows' in obj &&
        Array.isArray(obj.rows);
};

export const isColumnDef = (obj: any): obj is i_columnDef => {
    return 'name' in obj &&
        typeof obj.name === 'object' && isText(obj.name) &&
        ('sort' in obj ? typeof obj.sort === 'string' : true) &&
        ('description' in obj ? typeof obj.description === 'object' && isText(obj.description) : true);
};

export const isFormatting = (obj: any): obj is t_formatting => {
    return isFormattingText(obj) || isFormattingTable(obj);
};

export const isCategory = (obj: any): obj is i_categoryDto => {
    return typeof obj.id === 'string' &&
        isText(obj.title) &&
        Array.isArray(obj.entries) && obj.entries.every(isEntry);
};

export const isEntry = (obj: any): obj is i_entryDto => {
    return 'id' in obj && typeof obj.id === 'string' &&
        'color' in obj && typeof obj.color === 'string' &&
        'icon' in obj && typeof obj.icon === 'string' &&
        (obj.link === undefined || isInfo(obj.info)) &&
        (obj.refreshRate === undefined || typeof obj.refreshRate === 'number') &&
        isText(obj.name) &&
        Array.isArray(obj.sources) && obj.sources.every(isSourceDto);
};

export const isEntryContext = (obj: any): obj is i_entryContextDto => {
    return (obj.source === undefined || isRequest(obj.source) || isOperation(obj.source)) && isFormatting(obj.formatting);
};

export const isRequest = (obj: any): obj is i_request => {
    return obj !== undefined &&
        'url' in obj && typeof obj.url === 'string' &&
        (obj.dataRoot === undefined || typeof obj.dataRoot === 'string') &&
        (obj.permission === undefined || isPermission(obj.permission));
};

export const isPermission = (obj: any): obj is t_permission => {
    return obj !== undefined && (
        typeof obj === 'boolean' ||
        typeof obj === 'string' ||
        (Array.isArray(obj) && obj.every(o => typeof o === 'string'))
    );
}

export const isSourceDto = (obj: any): obj is i_sourceDto => {
    return obj !== undefined &&
        (isRequest(obj.request) || isOperation(obj.request)) &&
        isMapTemplate(obj.mapTemplate) &&
        isEntryContext(obj.context);
};

export const isInfo = (obj: any): obj is i_entryInfoDto => {
    return obj !== undefined &&
        'description' in obj && typeof isText(obj.description) &&
        (obj.fileDownload === undefined || (typeof obj.fileDownload === 'string' || isRequest(obj.fileDownload) || obj.fileDownload.every(isFileSourceDto))) &&
        (obj.link === undefined || typeof obj.link === 'string')
};

export const isFileSourceDto = (obj: any): obj is i_fileSourceDto => {
    return typeof obj === 'object' && obj !== null &&
        isRequest(obj.request) &&
        (obj.swapCoordinates === undefined || typeof obj.swapCoordinates === 'boolean') &&
        'geojsonTemplate' in obj && isGeojsonTemplateDto(obj.geojsonTemplate);
}

export const isGeojsonTemplateDto = (obj: any): obj is i_geojsonTemplateDto => {
    return typeof obj === 'object' && obj !== null &&
        isGeojsonTemplateGeometryDto(obj.geometry) &&
        typeof obj.properties === 'object';
}

export const isGeojsonTemplateGeometryDto = (obj: any): obj is i_geojsonTemplateGeometryDto => {
    return typeof obj === 'object' && obj !== null &&
        'type' in obj && typeof obj.type === 'string' &&
        'coordinates' in obj && (typeof obj.coordinates === 'string' || Array.isArray(obj.coordinates));
}

export const isGeojsonPoint = (obj: any): obj is i_geojsonTemplatePointDto => {
    return isGeojsonTemplateGeometryDto(obj) &&
        obj.type.toUpperCase() === GeoJsonTypes.POINT &&
        (typeof obj.coordinates === 'string' ||
            (Array.isArray(obj.coordinates) &&
                obj.coordinates.length === 2 &&
                (typeof obj.coordinates[0] === 'string' || typeof obj.coordinates[0] === 'number') &&
                (typeof obj.coordinates[1] === 'string' || typeof obj.coordinates[1] === 'number')));
}

export const isGeojsonLineString = (obj: any): obj is i_geojsonTemplateLineStringDto => {
    return isGeojsonTemplateGeometryDto(obj) &&
        obj.type.toUpperCase() === GeoJsonTypes.LINESTRING &&
        (typeof obj.coordinates === 'string' ||
            (Array.isArray(obj.coordinates) &&
                ((obj.coordinates as any[]).every(coord => coord.length === 2 &&
                    (typeof coord[0] === 'number' || typeof coord[0] === 'string') &&
                    (typeof coord[1] === 'number' || typeof coord[1] === 'string')))
            )
        );
};

export const isGeojsonPolygon = (obj: any): obj is i_geojsonTemplatePolygonDto => {
    return isGeojsonTemplateGeometryDto(obj) &&
        obj.type.toUpperCase() === GeoJsonTypes.POLYGON &&
        (typeof obj.coordinates === 'string' ||
            (Array.isArray(obj.coordinates) &&
                ((obj.coordinates as any[][]).every(subarray =>
                        subarray.every(coord => coord.length === 2 &&
                            (typeof coord[0] === 'number' || typeof coord[0] === 'string') &&
                            (typeof coord[1] === 'number' || typeof coord[1] === 'string')))
                )
            )
        );
};

export const isConditional = (obj: any): obj is i_conditional => {
    return obj !== undefined &&
        typeof obj === 'object' && obj !== null &&
        'exp' in obj && typeof obj.exp === 'string' &&
        'true' in obj && (typeof obj.true === 'string' || typeof obj.true === 'number' || typeof obj.true === 'boolean' || isConditional(obj.true) || isSwitch(obj.true)) &&
        'false' in obj && (typeof obj.false === 'string' || typeof obj.false === 'number' || typeof obj.false === 'boolean' || isConditional(obj.false) || isSwitch(obj.false));
}

export const isSwitch = (obj: any): obj is i_switch => {
    return obj !== undefined &&
        typeof obj === 'object' && obj !== null &&
        'default' in obj && (typeof obj.default === 'string' || typeof obj.default === 'number' || typeof obj.default === 'boolean' || isConditional(obj.default) || isSwitch(obj.default)) &&
        'cases' in obj && Array.isArray(obj.cases) && obj.cases.every(isCase);
}

export const isCase = (obj: any): obj is i_case => {
    return typeof obj === 'object' && obj !== null &&
        'exp' in obj && typeof obj.exp === 'string' &&
        'value' in obj && (typeof obj.value === 'string' || typeof obj.value === 'number' || typeof obj.value === 'boolean' || isConditional(obj.value) || isSwitch(obj.value))
}

export const isRouterPanel = (obj: any): obj is i_routerPanelDto => {
    return obj.type === PANEL_TYPES.ROUTER &&
        isText(obj.title);
}

export const isDataPanel = (obj: any): obj is i_dataPanelDto => {
    return obj !== undefined &&
        obj.id && typeof obj.id === "string" &&
        obj.type === PANEL_TYPES.DATA &&
        isText(obj.title) &&
        Array.isArray(obj.categories) && obj.categories.every(isCategory);
}

export const isAggregate = (obj: any): obj is i_aggregate => {
    return obj &&
        "on" in obj && typeof obj.on === 'string' &&
        (obj.mapping === undefined  ||
            (Array.isArray(obj.mappings) && obj.mappings.every((value: any) => isMapping(value)))
        ) &&
        (isRequest(obj.aggregate) || isOperation(obj.aggregate));
};

export const isMapping = (obj: any): obj is i_mapping => {
    return obj !== undefined && obj !== null && typeof obj === 'object' &&
        "type" in obj && Object.values(MAPPING_TYPE).includes(obj.type) &&
        "key" in obj && typeof obj.key === "string";
}

export const isJoin = (obj: any): obj is i_join => {
    return obj && Array.isArray(obj.on) && obj.on.every((item: any) => typeof item === 'string') &&
        (Array.isArray(obj.join) && obj.join.every((item: any) => isOperation(item) || isRequest(item)));
}

export const isFrom = (obj: any): obj is i_from => {
    return obj && typeof obj.on === 'string' && (isOperation(obj.from) || isRequest(obj.from));
}

export const isOperation = (obj: any): obj is t_operation => {
    return obj !== undefined && "on" in obj;
}

export const isValidator = (obj: any): obj is i_validator => {
    return obj !== undefined &&
        obj.attribute && typeof obj.attribute === "string" &&
        obj.value !== undefined &&
        (obj.path === undefined || typeof obj.path === "string");
}
