import {
    IProxyAggregate,
    IProxyCategory, IProxyData, IProxyDownload,
    IProxyEntry, IProxyFrom, IProxyGroup,
    IProxyJoin,
    IProxyRequest,
    IProxySource, TProxyOperation,
    TProxyRequest
} from "../interfaces/proxy.interfaces";
import {
    isCategoryDto,
    isEntryDto,
    isGeojsonGeometry,
    isGroupDto,
    isSourceTemplate, isStatic,
    isText
} from "./dto.guards";

export const isProxyRequest = (obj: any): obj is IProxyRequest =>
    obj &&
    typeof obj === "object" &&
    typeof obj.url === "string" &&
    (obj.dataRoot === undefined || typeof obj.dataRoot === "string") &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isProxyJoin = (obj: any): obj is IProxyJoin =>
    obj &&
    typeof obj === "object" &&
    Array.isArray(obj.on) &&
    obj.on.every((item: any) => typeof item === "string") &&
    Array.isArray(obj.join) &&
    obj.join.every(isProxyRequestUnion) &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isProxyAggregate = (obj: any): obj is IProxyAggregate =>
    obj &&
    typeof obj === "object" &&
    typeof obj.on === "string" &&
    isProxyRequestUnion(obj.aggregate) &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isProxyFrom = (obj: any): obj is IProxyFrom =>
    obj &&
    typeof obj === "object" &&
    typeof obj.on === "string" &&
    isProxyRequestUnion(obj.from) &&
    (obj.refreshRate === undefined || typeof obj.refreshRate === "number");

export const isProxyOperation = (obj: any): obj is TProxyOperation =>
    isProxyJoin(obj) || isProxyAggregate(obj) || isProxyFrom(obj);

// Guard for TProxyRequest
export const isProxyRequestUnion = (obj: any): obj is TProxyRequest =>
    isProxyRequest(obj) || isProxyJoin(obj) || isProxyAggregate(obj) || isProxyFrom(obj) || isStatic(obj);

// Guard for IProxySource
export const isProxySource = (obj: any): obj is IProxySource =>
    obj &&
    typeof obj === "object" &&
    isSourceTemplate(obj.template) &&
    (isProxyRequestUnion(obj.location) || isStatic(obj.location)) &&
    (isProxyRequestUnion(obj.context) || isStatic(obj.context)) &&
    isText(obj.formatting);

// Guard for IProxyEntry
export const isProxyEntry = (obj: any): obj is IProxyEntry =>
    obj &&
    typeof obj === "object" &&
    isEntryDto({ ...obj, download: undefined, sources: [] }) && // Check other fields from IEntryDto
    (obj.download === undefined || typeof obj.download === "string" || isProxyRequest(obj.download) || isProxyDownload(obj.download)) &&
    Array.isArray(obj.sources) &&
    obj.sources.every(isProxySource);

export const isProxyDownload = (obj: any): obj is IProxyDownload =>
    obj &&
    typeof obj === "object" &&
    isProxyRequest(obj.request) &&
    (obj.swapCoordinates === undefined || typeof obj.swapCoordinates === "boolean") &&
    typeof obj.geojsonTemplate === 'object' &&
    isGeojsonGeometry(obj.geojsonTemplate.geometry) &&
    typeof obj.geojsonTemplate.properties !== 'undefined';

// Guard for IProxyCategory
export const isProxyCategory = (obj: any): obj is IProxyCategory =>
    obj &&
    typeof obj === "object" &&
    isCategoryDto({ ...obj, entries: [] }) && // Check other fields from ICategoryDto
    Array.isArray(obj.entries) &&
    obj.entries.every(isProxyEntry);

// Guard for IProxyGroup
export const isProxyGroup = (obj: any): obj is IProxyGroup =>
    obj &&
    typeof obj === "object" &&
    isGroupDto({ ...obj, categories: [] }) && // Check other fields from IGroupDto
    Array.isArray(obj.categories) &&
    obj.categories.every(isProxyCategory);

// Guard for IProxyData
export const isProxyData = (obj: any): obj is IProxyData =>
    obj &&
    typeof obj === "object" &&
    Array.isArray(obj.groups) &&
    obj.groups.every(isProxyGroup);
