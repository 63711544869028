import {Base} from "@/Base";
import {Text} from "@/utils/text";
import {ref, Ref} from "vue";
import {i_genericDialog} from "@/types/interfaces/dialog.interfaces";
import {buildContainer} from "@/builder/container.builder";

const STD_WIDTH = 350;
const STD_HEIGHT = 350;
const STD_DIALOG: i_genericDialog = {
    title: Text.buildUnknown().getDataObject(),
    width: STD_WIDTH,
    color: "#80cc28",
    content: buildContainer().build(),
    actions: []
}

class DialogService extends Base {
    private genericDialog: Ref<i_genericDialog>;
    private state: Ref<boolean>;

    constructor() {
        super(DialogService.name);
        this.genericDialog = ref(JSON.parse(JSON.stringify(STD_DIALOG)) as i_genericDialog) as Ref<i_genericDialog>;
        this.state = ref(false);
    }

    public set(dialog: i_genericDialog) {
        this.genericDialog.value = dialog;
    }

    public getTitle() {
        return new Text(this.genericDialog.value.title);
    }

    public open() {
        this.state.value = true;
    }

    public close() {
        this.genericDialog.value = JSON.parse(JSON.stringify(STD_DIALOG)) as i_genericDialog;
        this.state.value = false;
    }

    public getHeight() {
        // TODO: use max height instead
        return undefined;
    }

    public getState() {
        return this.state;
    }

    public get() {
        return this.genericDialog.value;
    }
}

const dialogService = new DialogService();

export const useDialog = () => {
    return dialogService;
}
