import {
    i_parseExpression,
    i_switch,
    i_text
} from "@/types/interfaces/structure.interfaces";
import {buildStepper} from "@/builder/stepper.builder";
import {DataUtils} from "@/utils/data.utils";
import {computed, ref, Ref} from "vue";
import {CONTEXT_TYPES, USER_ROLES} from "@/types/enums/general.enums";
import {LANGUAGE, SID, THEME} from "@/types/enums/frontend.enums";
import {StringUtils} from "@/utils/string.utils";
import {buildContainer} from "@/builder/container.builder";
import {buildTabs} from "@/builder/tabs.builder";
import {Text} from "@/utils/text";
import {useConnection} from "@/services/connection.service";
import {FetchWorkerUtils} from "@/utils/fetch.worker.utils";
import {useStore} from "@/services/store.service";
import {useSnackbar} from "@/services/snackbar.service";
import {buildDialog} from "@/builder/dialog.builder";
import {useDialog} from "@/services/dialog.service";
import {Log} from "@/utils/log";
import {PROGRESS_STATES} from "@/types/enums/dialog.enums";
import {useGeoJSON} from "@/services/geojson.service";
import {ICreateEntryDto, IEntryDto, ISource, IText, IUpdateEntryDto} from "@/types/interfaces/dto.interfaces";
import {isRequest, isSwitch, isText} from "@/types/typeguards/dto.guards";
import {GeoJsonTypes} from "@/types/enums/general.enums";
import {GEOJSON_TYPES} from "@/types/enums/geojson.enums";

const log = new Log("entry.dialog");

export const entryDialog = (
    submitCallback: (data: IUpdateEntryDto | ICreateEntryDto, refLoading: Ref<boolean>) => void,
    options?: {
        data?: IEntryDto,
        submitText?: IText,
        title?: IText
    }
) => {
    const data = options?.data;
    const stepper = buildStepper();
    const isUpdate = options?.data !== undefined;
    // +++++++++++++++++++++++++++++
    //          References
    // +++++++++++++++++++++++++++++

    // STEP 1: Button Styling - Part 1
    const refsButtonTitle = DataUtils.createLangRefs();
    if (isText(data?.name)) {
        Object.values(LANGUAGE).forEach(language => {
            refsButtonTitle[language].value = data?.name[language];
        });
    }
    const refsButtonDesc = DataUtils.createLangRefs();
    const description = data?.description;
    if (isText(description)) {
        Object.values(LANGUAGE).forEach(language => {
            refsButtonDesc[language].value = description[language];
        });
    }
    const refButtonColor = ref(data?.color);
    const refButtonIcon = ref(data?.icon);
    const refButtonSourceUrl = ref(data?.link);
    const refUseRestrictionsOfCategory = ref(data?.restrictions === undefined);
    const refsRestrictions = {
        create: ref(data?.restrictions?.create ?? [USER_ROLES.ADMIN]),
        update: ref(data?.restrictions?.update ?? [USER_ROLES.ADMIN]),
        read: ref(data?.restrictions?.read),
        delete: ref(data?.restrictions?.delete ?? [USER_ROLES.ADMIN]),
    }
    const refsButtonTitleError = Object.values(LANGUAGE).reduce((previous, language) => {
        return { ...previous, [language]: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "") }
    }, {} as Record<LANGUAGE, Ref<boolean>>);
    const refButtonIconError = computed(() => !(refButtonIcon.value !== undefined && refButtonIcon.value !== ""));
    const refButtonColorError = computed(() => !(refButtonColor.value !== undefined && refButtonColor.value !== ""));
    const refButtonSourceUrlError = computed(() => !(StringUtils.isURL(refButtonSourceUrl.value) || refButtonSourceUrl.value === undefined || refButtonSourceUrl.value === ""));

    // STEP 2: Source - Part 1
    const refGeoDataUrl = ref();
    if (isRequest(data?.sources[0]?.location)) {
        log.debug(`Set geo data url to '${data?.sources[0]?.location.url}'`);
        refGeoDataUrl.value = data?.sources[0]?.location.url;
    }
    const refGeoDataUrlLoadingState = ref(false);
    const refGeoDataRequestBody = ref();
    const refFileInput: Ref<File[] | undefined> = ref();
    const refCollectionName: Ref<string | undefined> = ref();
    const refCollectionNameError =  computed(() => refCollectionName.value === "" || !refCollectionName.value);
    const refUploadDisabled = ref(false);
    const refAppendButton = ref(true);
    const refAppendButtonText = ref(Text.asString({
        en: "Upload",
        de: "Hochladen"
    }))
    const progressLabels = () => [
        {
            en: "Select file...",
            de: "Datei auswählen..."
        },
        {
            en: "Upload file...",
            de: "Datei hochladen..."
        },
        {
            en: "Process file (this may take a while)...",
            de: "Datei einlesen (kann länger dauern)..."
        },
        {
            en: "Check data...",
            de: "Daten überprüfen..."
        }
    ];
    const refProgressLabels = ref(progressLabels());
    const refProgressStates = ref([
        PROGRESS_STATES.DISABLED,
        PROGRESS_STATES.DISABLED,
        PROGRESS_STATES.DISABLED,
        PROGRESS_STATES.DISABLED
    ])
    const refFeaturePreview = ref({});
    const refFilterAttribute = ref();
    const refFilterAttributeError = computed(() => !(refFilterAttribute.value && DataUtils.getValueByPath(refFilterAttribute.value, refFeaturePreview.value) !== undefined))
    const refFilterAttributeValue = ref();
    const refCurrentCreatedCollection = ref();
    const refFilterAttributeValueError = computed(() => refFilterAttributeValue.value === undefined)
    const finishedProgressLabels = [
        {
            en: "Finished!",
            de: "Fertig!"
        }
    ]
    const refFinishProgressLabels = ref(finishedProgressLabels);
    const refFinishProgressStates = ref([
        PROGRESS_STATES.DISABLED
    ])
    const refAppendButtonDisabled = computed(() => refCollectionNameError.value || refFileInput.value === undefined || refProgressStates.value[1] === PROGRESS_STATES.PENDING || refProgressStates.value[2] === PROGRESS_STATES.PENDING);
    const refAppendButtonIcon = ref("mdi-upload");
    const refGeoDataRoot = ref();
    if (isRequest(data?.sources[0]?.location)) {
        log.debug(`Set data root to '${data?.sources[0].location.dataRoot ? data?.sources[0].location.dataRoot : ""}'`);
        refGeoDataRoot.value = data?.sources[0].location.dataRoot ? data?.sources[0].location.dataRoot : "";
    }
    const refGeoDataPreview = computed(() => refGeoDataRoot.value !== undefined && refGeoDataRequestBody.value !== undefined ? DataUtils.getValueByPath(refGeoDataRoot.value, refGeoDataRequestBody.value) : undefined);
    const refGeoDataUrlError = computed(() => !StringUtils.isURL(refGeoDataUrl.value));
    const refGeoDataPreviewError = computed(() => !(refGeoDataPreview.value !== undefined && Array.isArray(refGeoDataPreview.value) && refGeoDataPreview.value.every(gd => typeof gd === 'object')));

    // STEP 3: Select coordinate fields
    const refCoordinatesLongitude = ref(data?.sources && data.sources.length > 0 && Array.isArray(data.sources[0].template.coordinates) ? StringUtils.extractZmRef(data.sources[0].template.coordinates[1] as string) : undefined);
    const refCoordinatesLatitude = ref(data?.sources && data.sources.length > 0 && Array.isArray(data.sources[0].template.coordinates) ? StringUtils.extractZmRef(data.sources[0].template.coordinates[0] as string) : undefined);
    const refCoordinatesLatLonGeoJson = ref(data?.sources && data.sources.length > 0 && typeof data.sources[0].template.coordinates === "string" ? StringUtils.extractZmRef(data.sources[0].template.coordinates as string) : undefined);
    const refsCoordinatesName = DataUtils.createLangRefs();
    if (data?.sources[0]?.template?.name) {
        const name = data?.sources[0]?.template?.name;
        if (isText(name)) {
            Object.values(LANGUAGE).forEach(language => {
                refsCoordinatesName[language].value = name[language];
            });
        } else {
            Object.values(LANGUAGE).forEach(language => {
                refsCoordinatesName[language].value = name;
            });
        }
    }
    const refSwapCoordinates = ref(data?.sources && data?.sources[0].template?.swapCoordinates);
    const refsCoordinatesNamePreview = computed(() => Object.values(LANGUAGE).reduce((prev, language) => {
        return { ...prev, [language]: FetchWorkerUtils.replaceByMatrix(refsCoordinatesName[language].value, DataUtils.unproxy(refGeoDataPreview.value[0])) }
    }, {} as Record<LANGUAGE, string>))
    const refCoordinatesLongitudeError = computed(() => {
        if (refCoordinatesLongitude.value !== undefined && refGeoDataPreview.value !== undefined) {
            const value = DataUtils.getValueByPath(refCoordinatesLongitude.value + "", refGeoDataPreview.value[0]);
            return value === undefined || isNaN(Number(value));
        }
        return true;
    });
    const refCoordinatesLatitudeError = computed(() => {
        if (refCoordinatesLatitude.value !== undefined && refGeoDataPreview.value !== undefined) {
            const value = DataUtils.getValueByPath(refCoordinatesLatitude.value + "", refGeoDataPreview.value[0]);
            return value === undefined || isNaN(Number(value));
        }
        return true;
    });
    const refCoordinatesLatLonGeoJsonError = computed(() => {
        if (refCoordinatesLatLonGeoJson.value !== undefined && refGeoDataPreview.value !== undefined) {
            const value = DataUtils.getValueByPath(refCoordinatesLatLonGeoJson.value, refGeoDataPreview.value[0]);
            console.log(value);
            console.log(!(value !== undefined && Array.isArray(value) && value.every(v => typeof v === "number" || (Array.isArray(v) && v.every(va => typeof va === "number" || (Array.isArray(v) && v.every(va => typeof va === "number")))))));
            // check if NOT number array or array of number arrays or array of array of number arrays (currently ignoring multi polygons)
            return !(value !== undefined && Array.isArray(value) && value.every(v => typeof v === "number" || (Array.isArray(v) && v.every(va => typeof va === "number" || (Array.isArray(v) && v.every(va => typeof va === "number"))))));
        }
        return true;
    });
    const refCoordinatesNameError = computed(() => {
        return Object.values(LANGUAGE).reduce((prev, language) => prev || (refsCoordinatesNamePreview.value[language] === undefined || refsCoordinatesNamePreview.value[language] === ""), false);
    })
    const refsCoordinatesNameError = Object.values(LANGUAGE).reduce((previous, language) => {
        return { ...previous, [language]: computed(() => refsCoordinatesNamePreview.value[language] === undefined || refsCoordinatesNamePreview.value[language] === "") }
    }, {} as Record<LANGUAGE, Ref<boolean>>)

    const extractStringOrSwitchDefault = (value: any, def: any) => {
        if (value === undefined) {
            return def;
        }
        if (typeof value === "string") {
            return value;
        }
        if (isSwitch(value)) {
            return value.default;
        }
        throw new Error(`Invalid data was provided in value ${value}, expected undefined, string or switch object!`);
    }

    // STEP 5 - Styling the Map Element:
    const refStylingIcon = ref(extractStringOrSwitchDefault(data?.sources[0]?.template?.icon, refButtonIcon.value));
    const refStylingIconColor = ref(extractStringOrSwitchDefault(data?.sources[0]?.template?.iconColor, null));
    const refStylingColor = ref(extractStringOrSwitchDefault(data?.sources[0]?.template?.color, undefined));
    const refStyleConditionOperators = ref(["==", "!=", "<", ">", "<=", ">="])
    const refsMarkerStyleConditions: Record<string, { icon: Ref<string | undefined>, iconColor: Ref<string | null | undefined>, color: Ref<string | undefined>, conditionValue: Ref<string | undefined>, conditionOperator: Ref<string | undefined>, conditionField: Ref<string | undefined>, conditionFieldType: Ref<"number" | "string" | "boolean" | undefined> }> = {};

    const uniqueSwitchConditions = [
        ...data && isSwitch(data?.sources[0]?.template?.icon) ? [data.sources[0].template.icon] : [],
        ...data && isSwitch(data?.sources[0]?.template?.iconColor) ? [data.sources[0].template.iconColor] : [],
        ...data && isSwitch(data?.sources[0]?.template?.color) ? [data.sources[0].template.color] : []
    ]
        .reduce((prev_sw, curr_sw) => [ ...prev_sw, ...curr_sw.cases.reduce((prev_ca, curr_ca) => [...prev_ca, curr_ca.exp], [] as string[]) ], [] as string[])
        .map(exp => StringUtils.parseExpression(exp))
        .filter((exp, i, a) => a.indexOf(exp) === i)

    const getValueForExpOrDefault = (obj: any, exp: i_parseExpression, def: any) => {
        if (obj && isSwitch(obj)) {
            const queriedExp = obj.cases.find(c => JSON.stringify(StringUtils.parseExpression(c.exp)) == JSON.stringify(exp))
            return queriedExp?.value ?? def;
        }
        return def;
    }

    const refErrorMarkerStyleConditions = computed(() => !Object.values(refsMarkerStyleConditions)
        .reduce((prev, curr) =>
                prev &&
                curr.conditionField.value !== undefined &&
                curr.conditionOperator.value !== undefined,
            true)
    )

    // STEP 5: Context Source
    const refContextUseGeoDataAsSource = ref(
        isRequest(data?.sources[0]?.context) && isRequest(data?.sources[0]?.location) ?
            data?.sources[0]?.context?.url === data?.sources[0]?.location.url :
            undefined
    );
    const refContextRequestUrl = ref(isRequest(data?.sources[0]?.context) ? data?.sources[0]?.context?.url : undefined);

    const refContextPreviewFetched = ref();
    const refContextPreview = computed(() => refContextUseGeoDataAsSource.value === true ?
        Array.isArray(refGeoDataPreview.value) ? refGeoDataPreview.value[0] : undefined : refContextPreviewFetched.value);
    const refContextPreviewLoading = ref();
    const refContextPathToId = ref(refContextUseGeoDataAsSource.value && isRequest(data?.sources[0]?.context) && data?.sources[0]?.context?.dataRoot ? StringUtils.extractZmRef(data?.sources[0]?.context?.dataRoot) : undefined);
    const refContextIdPreview = computed(() => refContextUseGeoDataAsSource.value && refContextPathToId.value !== undefined && refContextPreview.value !== undefined ? DataUtils.getValueByPath(refContextPathToId.value, refContextPreview.value) : undefined);
    const refContextDataWasFetched = ref(false);

    const refContextPathToIdError = computed(() => {
        return !(refContextPathToId.value && refContextPreview.value &&
            DataUtils.getValueByPath(refContextPathToId.value, refContextPreview.value) !== undefined && refContextPathToId.value !== "")
    });
    const refContextRequestError = computed(() => {
        return !StringUtils.isURL(refContextRequestUrl.value) || !refContextDataWasFetched.value
    })

    // +++++++++++++++++++++++++++++
    //          Functions
    // +++++++++++++++++++++++++++++

    const proxyGeoDataUrl = (url: string) => {
        if (StringUtils.isURL(url)) {
            refGeoDataUrlLoadingState.value = true;
            console.log(`Proxy url ${url}`);
            return useConnection().proxyUrl(url, { truncateData: true, truncateOptions: { onlyObjectArrays: true } })
                .then((data: any) => {
                    log.debug(`Proxied to url ${url} : ${JSON.stringify(data)}`);
                    refGeoDataRequestBody.value = data
                    refGeoDataUrlLoadingState.value = false;
                })
                .catch(() => {
                    log.warn(`Unable to proxy to ${url}`);
                    refGeoDataRequestBody.value = undefined;
                    refGeoDataUrlLoadingState.value = false;
                });
        } else {
            refGeoDataRequestBody.value = undefined;
        }
        return Promise.reject();
    }
    const proxyGeoFileProcess = async (url: string) => {
        return proxyGeoDataUrl(refGeoDataUrl.value)
            .then(() => {
                refGeoDataRoot.value = "";
                refCoordinatesLatLonGeoJson.value = "geometry.coordinates";
                refSwapCoordinates.value = true;
                refContextRequestUrl.value = `${useGeoJSON().getCollectionFeaturesUrl(refCurrentCreatedCollection.value.name)}/{{ZM_REF[id]}}`
                refUploadDisabled.value = false;
                console.log(refGeoDataPreview.value);
                if (Array.isArray(refGeoDataPreview.value)) {
                    const url = FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0]));
                    fetchContextPreviewByUrl(url);
                }
                refFinishProgressStates.value[0] = PROGRESS_STATES.SUCCESS;
            })
            .catch((error: any) => {
                refFinishProgressStates.value[0] = PROGRESS_STATES.FAILURE;
                useSnackbar().error(Text.asString({
                    en: `During test fetching of the data an error occurred: ${error.message}`,
                    de: `Während einer Testanfrage ist ein Fehler aufgetreten: ${error.message}`
                }))
            })
    }
    const uploadGeoFileProcess = async (file: File, name: string) => {
        try {
            // Start file upload
            refProgressLabels.value[0] = {
                en: `File '${file.name}' selected!`,
                de: `Datei '${file.name}' ausgewählt!`
            };
            refProgressStates.value[1] = PROGRESS_STATES.PENDING;
            const uploadResponse = await useGeoJSON().uploadFile(file);

            // Handle successful upload
            refProgressStates.value[1] = PROGRESS_STATES.SUCCESS;
            refProgressLabels.value[1] = {
                en: `File uploaded!`,
                de: `Datei hochgeladen!`
            };
            refProgressStates.value[2] = PROGRESS_STATES.PENDING;
            console.log(`File uploaded: ${JSON.stringify(uploadResponse)}`);

            // Add data to collection
            const addDataResponse = await useGeoJSON().addDataToCollection({
                name: name,
                uploadId: uploadResponse.data.uploadId,
            });

            // Handle successful data processing
            console.log(`File processed: ${JSON.stringify(addDataResponse)}`);
            refProgressStates.value[2] = PROGRESS_STATES.SUCCESS;
            refProgressLabels.value[2] = {
                en: `File processed!`,
                de: `Datei verarbeitet!`
            };

            // Check collection size
            refProgressStates.value[3] = PROGRESS_STATES.PENDING;
            const collectionsResponse = await useGeoJSON().getCollections();
            refCurrentCreatedCollection.value = collectionsResponse.data.find(c => c.name === addDataResponse.data.collection);
            if (refCurrentCreatedCollection.value === undefined) {
                throw new Error(`Cannot find collection '${addDataResponse.data.collection}' that was just created!`);
            }
            if (refCurrentCreatedCollection.value.documents > 60000) {
                refFeaturePreview.value = (await useGeoJSON().getAllFeatures(refCurrentCreatedCollection.value.name, { limit: 1 })).data[0];
                refProgressStates.value[3] = PROGRESS_STATES.ALERT;
                refProgressLabels.value[3] = {
                    en: `The processed data is to large!`,
                    de: `Datensammlung zu groß!`
                };
                refUploadDisabled.value = false;
            } else {
                refProgressStates.value[3] = PROGRESS_STATES.SUCCESS;
                refGeoDataUrl.value = useGeoJSON().getCollectionFeaturesUrl(refCurrentCreatedCollection.value.name);
                refFinishProgressStates.value[0] = PROGRESS_STATES.PENDING;
                proxyGeoFileProcess(refGeoDataUrl.value);
            }
        } catch (error: any) {
            // Distinguish between upload and processing errors
            if (refProgressStates.value[1] === PROGRESS_STATES.PENDING) {
                refProgressStates.value[1] = PROGRESS_STATES.FAILURE;
                useSnackbar().error(
                    Text.asString({
                        en: `An error occurred during the file upload: ${error.message}`,
                        de: `Beim Hochladen der Datei ist ein Fehler aufgetreten: ${error.message}`,
                    })
                );
            } else if (refProgressStates.value[2] === PROGRESS_STATES.PENDING) {
                refProgressStates.value[2] = PROGRESS_STATES.FAILURE;
                useSnackbar().error(
                    Text.asString({
                        en: `An error occurred during the file processing: ${error.message}`,
                        de: `Beim Verarbeiten der Datei ist ein Fehler aufgetreten: ${error.message}`,
                    })
                );
            } else if (refProgressStates.value[3] === PROGRESS_STATES.PENDING) {
                refProgressStates.value[3] = PROGRESS_STATES.FAILURE;
                useSnackbar().error(
                    Text.asString({
                        en: `An error occurred during the data check: ${error.message}`,
                        de: `Beim Überprüfen der Daten ist ein Fehler aufgetreten: ${error.message}`,
                    })
                );
            }
            refUploadDisabled.value = false;
        }
    }
    const fetchContextPreviewByUrl = (url: string) => {
        if (StringUtils.isURL(url)) {
            refContextPreviewLoading.value = true;
            useConnection().proxyUrl(url, {
                truncateData: true, truncateOptions: { onlyObjectArrays: true }
            }).then((data: any) => {
                refContextDataWasFetched.value = true;
                refContextPreviewFetched.value = data;
                refContextPreviewLoading.value = false;
            }).catch((error: any) => {
                refContextPreviewLoading.value = false;
                refContextDataWasFetched.value = false;
                useSnackbar().error(Text.asString({
                    en: "An error occurred during data fetching!",
                    de: "Bei der Datenübertragung ist ein Fehler aufgetreten!"
                }))
            });
        }
    }


    const markerStyleEditorFactory = (
        _refIcon: Ref<string | undefined>,
        _refIconColor: Ref<string | null | undefined>,
        _refColor: Ref<string | undefined>,
        _condition?: {
            refConditionValue: Ref<string | undefined>,
            refConditionOperator: Ref<string | undefined>,
            refConditionField: Ref<string | undefined>,
            refConditionFieldType: Ref<string | undefined>
        }
    ) => {
        const containerBuilder = buildContainer();
        if (_condition) {
            containerBuilder
                .addContainer(
                    buildContainer()
                        .setHorizontal()
                        .addInput({ en: "Attribute", de: "Attribut" }, _condition.refConditionField, { cols: 2, error: computed(() => _condition.refConditionField.value === undefined || _condition.refConditionField.value === "") } )
                        .addJsonPicker(computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined), (event: any) => { _condition.refConditionField.value = event.path; _condition.refConditionFieldType.value = typeof DataUtils.getValueByPath(event.path, refGeoDataPreview.value[0]) }, { size: "large" })
                        .addSelect({ en: "<>", de: "<>" }, _condition.refConditionOperator, refStyleConditionOperators, { hideArrow: true, error: computed(() => _condition.refConditionOperator.value === undefined) })
                        .addInput({ en: "Value", de: "Wert" }, _condition.refConditionValue, { cols: 2 })
                        .build()
                )
        }
        containerBuilder
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput({ en: "Element Icon", de: "Symbol des Elements" }, _refIcon, { prependInnerIcon: _refIcon, cols: 5, readonly: ref(true) })
                    .addIconPicker(_refIcon, { size: "large", block: true })
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        { en: "Icon Color", de: "Symbolfarbe" },
                        computed(() => _refIconColor.value === null ? Text.asString({ en: "Automatic", de: "Automatisch" }) : _refIconColor.value),
                        { prependInnerIcon: computed(() => _refIconColor.value !== null ? "mdi-circle" : "mdi-circle-half-full"), innerIconColor: computed(() => _refIconColor.value !== null ? _refIconColor.value : useStore().get(SID.THEME, THEME.LIGHT) == THEME.LIGHT ? "#000" : "#FFF"), cols: 2, readonly: ref(true) }
                    )
                    .addColorPicker( _refIconColor, { size: "large", block: true, autoColor: null })
                    .addInput({ en: "Element Color", de: "Elementfarbe" }, _refColor, { prependInnerIcon: ref("mdi-circle"), innerIconColor: _refColor, cols: 2, readonly: ref(true) })
                    .addColorPicker(_refColor, { size: "large", block: true })
                    .build()
            )
            .addPreviewMarker(
                _refIcon,
                _refIconColor,
                _refColor
            )
        return containerBuilder;
    }

    const addStyleConditionTab = (uuid: string, numOfTabs: number) => {
        tabsBuilderMarkerStyle
            .addTab(
                { en: `Condition #${numOfTabs}`, de: `Bedingung #${numOfTabs}` },
                markerStyleEditorFactory(
                    refsMarkerStyleConditions[uuid.toString()].icon,
                    refsMarkerStyleConditions[uuid.toString()].iconColor,
                    refsMarkerStyleConditions[uuid.toString()].color,
                    {
                        refConditionValue: refsMarkerStyleConditions[uuid.toString()].conditionValue,
                        refConditionOperator: refsMarkerStyleConditions[uuid.toString()].conditionOperator,
                        refConditionField: refsMarkerStyleConditions[uuid.toString()].conditionField,
                        refConditionFieldType: refsMarkerStyleConditions[uuid.toString()].conditionFieldType
                    }
                )
                    .addButton( { en: "Remove Condition", de: "Bedingung entfernen" }, () => {
                        const idx = tabsBuilderMarkerStyle.build().value.tabs.value.findIndex(tab => tab.options?.id === uuid.toString())
                        if (idx >= 0) {
                            refTabsMarkerStyleCurrent.value = Math.max(idx - 1, 0);
                            delete refsMarkerStyleConditions[uuid.toString()];
                            tabsBuilderMarkerStyle.removeTab(idx);
                        } else {
                            console.warn(`Cannot remove condition element with id ${uuid.toString()}, index ${idx}!`);
                        }
                    }, { color: computed(() => "error"), class: "w-full mb-[-15px]" }).build()
                ,
                {
                    id: uuid.toString()
                }
            );
    }

    if (data?.sources && data.sources.length > 0 && isRequest(data?.sources[0].location)) {
        refGeoDataUrl.value = data.sources[0].location.url;
        proxyGeoDataUrl(refGeoDataUrl.value).then(() => {
            if (refContextRequestUrl.value !== undefined) {
                if (Array.isArray(refGeoDataPreview.value)) {
                    const url = FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0]));
                    fetchContextPreviewByUrl(url);
                }

                uniqueSwitchConditions.forEach(exp => {
                    const uuid = self.crypto.randomUUID().toString();
                    refsMarkerStyleConditions[uuid] = {
                        icon: ref(getValueForExpOrDefault(data?.sources[0]?.template?.icon, exp, refStylingIcon.value)),
                        iconColor: ref(getValueForExpOrDefault(data?.sources[0]?.template?.iconColor, exp, refStylingIconColor.value)),
                        color: ref(getValueForExpOrDefault(data?.sources[0]?.template?.color, exp, refStylingColor.value)),
                        conditionValue: ref(exp.operand2),
                        conditionField: ref(StringUtils.isZmRef(exp.operand1) ? StringUtils.extractZmRef(exp.operand1) : exp.operand1),
                        conditionFieldType: ref(typeof DataUtils.getValueByPath(StringUtils.extractZmRef(exp.operand1), refGeoDataPreview.value[0]) as "string" | "boolean" | "number"),//["string", "boolean", "number"].includes(typeof exp.operand1) ? typeof exp.operand1 as "number" | "boolean" | "string" : undefined),
                        conditionOperator: ref(exp.operator)
                    }
                    addStyleConditionTab(uuid, Object.keys(refsMarkerStyleConditions).indexOf(uuid)+1);
                });
            }
        })
    }

    const refTabsMarkerStyleCurrent = ref(0);
    const tabsBuilderMarkerStyle = buildTabs()
        .setTabsRef(refTabsMarkerStyleCurrent)
        .addTab(
            {
                en: "Default",
                de: "Standard"
            },
            markerStyleEditorFactory(
                refStylingIcon,
                refStylingIconColor,
                refStylingColor
            ).build()
        )

    // +++++++++++++++++++++++++++++
    //     Dialog Definition
    // +++++++++++++++++++++++++++++

    // STEP 1: Button Styling - Part 1
    stepper.addStep(
        {
            en: "Button",
            de: "Schaltfläche"
        },
        "mdi-gesture-tap-button",
        buildContainer()
            .addHeader({
                en: "Button Styling",
                de: "Visualisierung der Schaltfläche"
            })
            .addDesc({
                en: "Choose an icon, color, and authorization level for the data. Optionally, add a description and link to an explanatory source page. Set user roles for data access, or leave blank to make it public.",
                de: "Wähle ein Icon, eine Farbe und die Berechtigungsstufe für die Daten. Optional kannst du eine Beschreibung und einen Link zu einer weiterführenden Webseite anfügen. Ohne Angabe von Rollen sind die Daten für alle zugänglich."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Icon",
                            de: "Symbol"
                        },
                        refButtonIcon,
                        {
                            prependInnerIcon: refButtonIcon,
                            cols: 3,
                            error: refButtonIconError,
                            readonly: ref(true)
                        }
                    )
                    .addIconPicker(
                        refButtonIcon,
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .addInput(
                        {
                            en: "Color",
                            de: "Farbe"
                        },
                        refButtonColor,
                        {
                            prependInnerIcon: computed(() => refButtonColor.value !== undefined && refButtonColor.value !== "" ? "mdi-circle" : undefined),
                            innerIconColor: refButtonColor,
                            error: refButtonColorError,
                            cols: 3,
                            readonly: ref(true)
                        }
                    )
                    .addColorPicker(
                        refButtonColor,
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addInput(
                {
                    en: "External Link",
                    de: "Verlinkung"
                },
                refButtonSourceUrl,
                {
                    error: refButtonSourceUrlError
                }
            )
            .addDesc({
                en: "Define what actions can be performed by what roles.",
                de: "Definiere welche Rollen welche Aktionen durchführen dürfen."
            })
            .addCheckbox({
                en: "Use restrictions of category",
                de: "Zugriffsbeschränkungen von Kategorie nutzen"
            }, refUseRestrictionsOfCategory)
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addSelect({
                            en: `Edit entry`,
                            de: `Daten bearbeiten`
                        },
                        refsRestrictions.update,
                        computed(() => Object.values(USER_ROLES)),
                        {
                            multiple: true,
                            disabled: refUseRestrictionsOfCategory
                        })
                    .addSelect({
                            en: `Delete entry`,
                            de: `Daten löschen`
                        },
                        refsRestrictions.delete,
                        computed(() => Object.values(USER_ROLES)),
                        {
                            multiple: true,
                            disabled: refUseRestrictionsOfCategory
                        })
                    .build()
            )
            .addDataEntryPreview(
                computed(() => {
                    const title = refsButtonTitle[Object.values(LANGUAGE)[0]].value;
                    return Text.buildFromString(title ? title : "").getDataObject()
                }),
                refButtonColor,
                refButtonIcon,
                computed(() => {
                    const desc = refsButtonDesc[Object.values(LANGUAGE)[0]].value;
                    return desc ? Text.buildFromString(desc).getDataObject() : undefined
                }),
                refButtonSourceUrl,
                computed(() => {
                    return {
                        create: refsRestrictions.create.value,
                        read: refsRestrictions.read.value,
                        update: refsRestrictions.update.value,
                        delete: refsRestrictions.delete.value
                    }
                })
            )
            .build(),
        () => !refButtonIconError.value && !refButtonColorError.value && !refButtonSourceUrlError.value
    );
    // STEP 1: Button Styling - Part 2
    stepper.addStep(
        {
            en: "Description",
            de: "Beschreibung"
        },
        "mdi-gesture-tap-button",
        buildContainer()
            .addHeader({
                en: "Description",
                de: "Beschreibung"
            })
            .addDesc({
                en: "Choose the Button title in different languages. You may add a description to provide more Details about the data.",
                de: "Wähle den Titel der Schaltfläche in verschiedenen Sprachen. Optional kann eine Beschreibung angegeben werden."
            })
            .addTabs(
                buildTabs()
                    .setOptions({
                        fixed: true
                    })
                    .addMultipleTabs(Object.values(LANGUAGE).map(language => {
                        return {
                            title: Text.buildFromString(language.toUpperCase()).getDataObject(),
                            content: buildContainer()
                                .addInput(
                                    {
                                        en: `Title (${language.toUpperCase()})`,
                                        de: `Titel (${language.toUpperCase()})`
                                    },
                                    refsButtonTitle[language],
                                    {
                                        error: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "")
                                    }
                                )
                                .addTextEditor(
                                    {
                                        en: `Description (${language.toUpperCase()})`,
                                        de: `Beschreibung (${language.toUpperCase()})`
                                    },
                                    refsButtonDesc[language],
                                    {
                                        height: 150
                                    }
                                )
                                .addDataEntryPreview(
                                    computed(() => {
                                        const title = refsButtonTitle[language].value;
                                        return Text.buildFromString(title ? title : "").getDataObject()
                                    }),
                                    refButtonColor,
                                    refButtonIcon,
                                    computed(() => {
                                        const desc = refsButtonDesc[language].value;
                                        return desc ? Text.buildFromString(desc).getDataObject() : undefined
                                    }),
                                    refButtonSourceUrl,
                                    computed(() => {
                                        return {
                                            create: refsRestrictions.create.value,
                                            read: refsRestrictions.read.value,
                                            update: refsRestrictions.update.value,
                                            delete: refsRestrictions.delete.value
                                        }
                                    })
                                )
                                .build(),
                            options: {
                                prependIcon: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "" ? "mdi-pencil-circle" : "mdi-check-circle"),
                                error: refsButtonTitleError[language],
                                color: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "" ? "#f62e36" : "#80cc28"),
                                colorInactive: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "" ? "#f62e36" : "#80cc28")
                            }
                        }
                    }))
                    .build()
            )
            .build(),
        () => Object.values(refsButtonTitleError).reduce((prev, curr) => prev && !curr.value, true) &&
            !refButtonIconError.value && !refButtonColorError.value && !refButtonSourceUrlError.value
    );
    // STEP 2: Source - Part 1
    stepper.addStep(
        {
            en: "Source",
            de: "Quelle"
        },
        "mdi-link",
        buildContainer()
            .addHeader({
                en: "Static Source for Geodata",
                de: "Statische Quelle der Geodaten"
            })
            .addDesc({
                en: "If your data is provided via a dynamic source (API link etc.) skip this step. Upload a file containing geo data (.json, .gpkg, .shp or any of them in a .zip) and choose a name for the data collection (e.g. \"stadtradeln2024\", do notice that spaces and other special characters will be replaced with underscores during processing).",
                de: "Wenn die Daten über eine dynamische Quelle (API Link etc.) vorliegen überspringe diesen Punkt. Lade eine Datei mit Geo-Daten hoch (.json, .gpkg, .shp oder diese in einer .zip) und wähle einen Namen für die Datensammlung (z.B. \"stadtradeln2024\", beachten hierbei, dass Leer- und Sonderzeichen während der Datenverarbeitung mit Unterstrichen ersetzt werden)."
            })
            .addInput(
                {
                    en: "Name for data collection",
                    de: "Name der Datensammlung"
                },
                refCollectionName,
                {
                    error: refCollectionNameError
                }
            )
            .addFileInput(
                {
                    en: "Upload Geodata",
                    de: "Geodaten hochladen"
                },
                refFileInput,
                {
                    disabled: refUploadDisabled,
                    appendButton: refAppendButton,
                    appendButtonDisabled: refAppendButtonDisabled,
                    appendButtonText: refAppendButtonText,
                    appendButtonIcon: refAppendButtonIcon,
                    onFileSelect: (event: any) => {
                        console.log("File input changed!");
                        console.log(event[0]);
                        refFileInput.value = event && event.length > 0 ? event : undefined;
                        refProgressLabels.value = progressLabels();
                        refFinishProgressLabels.value = finishedProgressLabels;
                        refFinishProgressStates.value = [PROGRESS_STATES.DISABLED];
                        refProgressStates.value.forEach((p, i) => { refProgressStates.value[i] = PROGRESS_STATES.DISABLED });
                        if (refFileInput.value) {
                            refProgressStates.value[0] = PROGRESS_STATES.SUCCESS;
                        } else {
                            refProgressStates.value[0] = PROGRESS_STATES.FAILURE;
                        }
                    },
                    onClickButton: (event: any) => {
                        refUploadDisabled.value = true;
                        const fileValue = refFileInput.value;
                        if (fileValue && fileValue.length > 0 && refCollectionName.value) {
                            uploadGeoFileProcess(fileValue[0], refCollectionName.value)
                        }
                    },
                }
            )
            .addProgressSteps(
                refProgressLabels,
                refProgressStates
            )
            .addContainer(
                buildContainer()
                    .setOptions({
                        hidden: computed(() => !(refProgressStates.value[3] === PROGRESS_STATES.ALERT))
                    })
                    .addDesc({
                        en: "The data contains more than 60,000 datapoints - this might lead to long loading times or even crashes for the user system. Please select an attribute to filter by or confirm to ignore the issue.",
                        de: "Die Daten enthalten mehr als 60.000 Datenpunkte - dies kann zu langen Ladezeiten oder sogar zum Absturz des Benutzersystems führen. Bitte wähle ein Attribut aus nach dem gefiltert wird, oder klicke ignorieren."
                    })
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setOptions({
                        hidden: computed(() => !(refProgressStates.value[3] === PROGRESS_STATES.ALERT))
                    })
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Filter Attribute",
                            de: "Filter Attribut"
                        },
                        refFilterAttribute,
                        {
                            error: refFilterAttributeError,
                            cols: 5
                        }
                    )
                    .addJsonPicker(
                        refFeaturePreview,
                        (event:any) => { refFilterAttribute.value = event.path },
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setOptions({
                        hidden: computed(() => !(refProgressStates.value[3] === PROGRESS_STATES.ALERT))
                    })
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Filter for value...",
                            de: "Filter nach Wert..."
                        },
                        refFilterAttributeValue,
                        {
                            error: refFilterAttributeValueError,
                            cols: 2
                        }
                    )
                    .addButton(
                        {
                            en: "Confirm",
                            de: "Bestätigen"
                        },
                        () => {
                            if (refCurrentCreatedCollection.value) {
                                refGeoDataUrl.value = useGeoJSON().getCollectionFeaturesUrl(
                                    refCurrentCreatedCollection.value.name,
                                    { filter: JSON.stringify({ [refFilterAttribute.value]: typeof DataUtils.getValueByPath(refFilterAttribute.value, refFeaturePreview.value) === "number" ? Number(refFilterAttributeValue.value) : refFilterAttributeValue.value }) }
                                );
                                refProgressLabels.value[3] = {
                                    en: "Data checked and filtered!",
                                    de: "Daten überprüft und gefiltert!"
                                }
                                refProgressStates.value[3] = PROGRESS_STATES.SUCCESS;
                                refFinishProgressStates.value[0] = PROGRESS_STATES.PENDING;
                                proxyGeoFileProcess(refGeoDataUrl.value);
                            }
                        },
                        {
                            disabled: computed(() => refFilterAttributeValueError.value || refFilterAttributeError.value),
                            cols: 2,
                            size: "large",
                            class: "w-full"
                        }
                    )
                    .addButton(
                        {
                            en: "Ignore",
                            de: "Ignorieren"
                        },
                        () => {
                            if (refCurrentCreatedCollection.value) {
                                refGeoDataUrl.value = useGeoJSON().getCollectionFeaturesUrl(refCurrentCreatedCollection.value.name);
                                refProgressLabels.value[3] = {
                                    en: "Data checked, large data size was ignored!",
                                    de: "Daten überprüft, große Datenmenge wurde ignoriert!"
                                }
                                refProgressStates.value[3] = PROGRESS_STATES.ALERT;
                                refFinishProgressStates.value[0] = PROGRESS_STATES.PENDING;
                                proxyGeoFileProcess(refGeoDataUrl.value);
                            }
                        },
                        {
                            cols: 2,
                            size: "large",
                            color: computed(() => "error"),
                            class: "w-full"
                        }
                    )
                    .build()
            )
            .addProgressSteps(
                refFinishProgressLabels,
                refFinishProgressStates,
                { indexDelta: 4, class: "mt-[-8px]" }
            )
            .build(),
        () => true
    )
    // STEP 2: Source - Part 2
    stepper.addStep(
        {
            en: "Pick Data",
            de: "Datenauswahl"
        },
        "mdi-link",
        buildContainer()
            .addHeader({
                en: "Pick relevant Data",
                de: "Relevante Daten auswählen"
            })
            .addDesc({
                en: "Provide the URL to the data source.",
                de: "Geben Sie die URL zur Daten-Quelle an."
            })
            .addInput(
                {
                    en: "URL",
                    de: "URL"
                },
                refGeoDataUrl,
                {
                    error: refGeoDataUrlError,
                    loading: refGeoDataUrlLoadingState,
                    onChange: () => {
                        proxyGeoDataUrl(refGeoDataUrl.value);
                    }
                }
            )
            .addDesc({
                en: "Select the attribute which contains the data array you want to use for generating the map points. The elements of the array should contain coordinates somewhere.",
                de: "Wähle ein Attribut aus, das eine Liste von Objekten enthält. Auf Basis der Objekte werden die Punkte auf der Karte generiert. Die Objekte in der Liste sollten an irgendeiner Stelle Geo-Koordinaten enthalten."
            })
            .addJsonArea(
                {
                    en: "Select data root",
                    de: "Datenursprung auswählen"
                },
                refGeoDataRequestBody,
                {
                    rows: 8,
                    onClick: (event: any) => {
                        refGeoDataRoot.value = event.path;
                    }
                }
            )
            .addDesc({
                en: "This preview shows what data array will be used as a basis for the map element locations (e.g. marker locations).",
                de: "Diese Vorschau zeigt welche Datenliste später für die Verortung der Kartenelement (z.B. Marker) verwendet wird."
            })
            .addJsonArea(
                {
                    en: "Data Preview",
                    de: "Datenvorschau"
                },
                refGeoDataPreview,
                {
                    rows: 8,
                    error: refGeoDataPreviewError
                }
            )
            .build(),
        () => !refGeoDataUrlError.value && !refGeoDataPreviewError.value
    )
    // STEP 3: Select coordinate fields
    stepper.addStep(
        {
            en: "Coordinates",
            de: "Koordinaten"
        },
        "mdi-map-marker-circle",
        buildContainer()
            .addHeader({
                en: "Map Element",
                de: "Kartenelement"
            })
            .addDesc({
                en: "Choose the attributes containing the latitude and longitude. The value must be numeric or a string that can be converted to a number (e.g. 42.123 or \"42.123\").",
                de: "Wähle die Attribute aus, welche den Wert für den Breitengrad bzw. Längengrad enthalten. Der Wert muss als Nummer oder als Zeichenkette die in eine Nummer umwandelbar ist vorliegen (z.B. 42.123 oder \"42.123\")."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Longitude",
                            de: "Längengrad"
                        },
                        refCoordinatesLongitude,
                        {
                            error: refCoordinatesLongitudeError,
                            cols: 5
                        }
                    )
                    .addJsonPicker(
                        computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined),
                        (event:any) => {
                            refCoordinatesLongitude.value = event.path
                        },
                        {
                            size: "large",
                            block: true,
                            closeOnContentClick: true
                        }
                    )
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Latitude",
                            de: "Breitengrad"
                        },
                        refCoordinatesLatitude,
                        {
                            error: refCoordinatesLatitudeError,
                            cols: 5
                        }
                    )
                    .addJsonPicker(
                        computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined),
                        (event:any) => { refCoordinatesLatitude.value = event.path },
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addDivider({
                en: "OR",
                de: "ODER"
            })
            .addDesc({
                en: "Choose one field that contains the whole geo information instead. The field must be of a GeoJSON like array structure ([number, number] for a Point, [[number, number], [number, number]] for a LineString etc.).",
                de: "Wähle stattdessen ein Feld, das die gesamten Geoinformationen enthält. Das Feld muss eine GeoJSON-artige Struktur aufweisen ([Zahl, Zahl] als Point, [[Zahl, Zahl], [Zahl, Zahl]] als LineString etc.)."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Geo-Structure",
                            de: "Geo-Struktur"
                        },
                        refCoordinatesLatLonGeoJson,
                        {
                            error: refCoordinatesLatLonGeoJsonError,
                            cols: 5
                        }
                    )
                    .addJsonPicker(
                        computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined),
                        (event:any) => { refCoordinatesLatLonGeoJson.value = event.path },
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addDesc({
                en: "The coordinates have to be arranged as [ Latitude, Longitude ]. If the coordinates are in a different order you can check the following box to fix this issue.",
                de: "Die Koordinaten müssen als [ Längengrad, Breitengrad ] angeordnet sein. Wenn die Reihenfolge in den vorliegenden Daten anders ist, markiere die folgende Auswahlbox um dies zu beheben."
            })
            .addCheckbox(
                {
                    en: "Swap Coordinates (e.g. [ 14.123, 50.123 ] becomes [ 50.123, 14.123 ])",
                    de: "Koordinaten tauschen (z.B. [ 14.123, 50.123 ] wird zu [ 50.123, 14.123 ])"
                },
                refSwapCoordinates
            )
            .build(),
        () => (!refCoordinatesLatitudeError.value && !refCoordinatesLongitudeError.value) || !refCoordinatesLatLonGeoJsonError.value
    )
    // STEP 4: Select naming convention
    stepper.addStep(
        {
            en: "Naming",
            de: "Benennung"
        },
        "mdi-map-marker-circle",
        buildContainer()
            .addHeader({
                en: "Naming Convention",
                de: "Benennungsweise"
            })
            .addDesc({
                en: "Select a naming convention for the map elements - it may be static (same name for each element) or dynamic (based upon values from the data array).",
                de: "Wähle eine Benennungsweise für die Kartenelemente, diese kann statisch (für jedes Element gleich) oder dynamisch (anhand von Werten aus der Datenliste) sein."
            })
            .addTabs(
                buildTabs()
                    .setOptions({
                        hidden: computed(() => refFormattingSelectedType.value !== "TEXT"),
                        fixed: true
                    })
                    .addMultipleTabs(Object.values(LANGUAGE).map((language) => {
                        return {
                            title: Text.buildFromString(language.toUpperCase()).getDataObject(),
                            content: buildContainer()
                                .addContainer(
                                    buildContainer()
                                        .setHorizontal()
                                        .addInput(
                                            {
                                                en: `Marker Title (${language.toUpperCase()})`,
                                                de: `Marker Benennung (${language.toUpperCase()})`
                                            },
                                            refsCoordinatesName[language],
                                            {
                                                cols: 6,
                                                error: refsCoordinatesNameError[language]
                                            }
                                        )
                                        .addJsonPicker(
                                            computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined),
                                            (event:any) => {
                                                refsCoordinatesName[language].value = refsCoordinatesName[language].value ? refsCoordinatesName[language].value + StringUtils.buildZmRef(event.path) : StringUtils.buildZmRef(event.path) + "";
                                            },
                                            {
                                                size: "large",
                                                block: true
                                            }
                                        )
                                        .build()
                                )
                                .addInput(
                                    {
                                        en: `Preview (${language.toUpperCase()})`,
                                        de: `Vorschau (${language.toUpperCase()})`
                                    },
                                    computed(() => refGeoDataPreview.value ? FetchWorkerUtils.replaceByMatrix(refsCoordinatesName[language].value, DataUtils.unproxy(refGeoDataPreview.value[0])) : undefined),
                                    {
                                        readonly: ref(true),
                                        error: refsCoordinatesNameError[language]
                                    }
                                )
                                .build(),
                            options: {
                                prependIcon: computed(() => refsCoordinatesNameError[language].value ? "mdi-pencil-circle" : "mdi-check-circle"),
                                error: refsCoordinatesNameError[language],
                                color: computed(() => refsCoordinatesNameError[language].value ? "#f62e36" : "#80cc28"),
                                colorInactive: computed(() => refsCoordinatesNameError[language].value ? "#f62e36" : "#80cc28")
                            }
                        }
                    }))
                    .build()
            )
            .build(),
        () => !refCoordinatesNameError.value
    )
    // STEP 5 - Map Element Styling:
    stepper.addStep(
        {
            en: "Styling",
            de: "Visualisierung"
        },
        "mdi-brush-variant",
        buildContainer()
            .addHeader({
                en: "Styling",
                de: "Visualisierung"
            })
            .addDesc({
                en: "Choose the colors and the icon for the markers. You may add conditions to make the colors dependant on certain data values.",
                de: "Wähle die Farben und das Symbol aus, die für die Marker verwendet werden sollen. Soll die Farbgebung von gewissen Datenwerten abhängen, so füge eine entsprechende Bedingung hinzu."
            })
            .addTabs(tabsBuilderMarkerStyle.build())
            .addButton(
                {
                    en: "Add Style Condition",
                    de: "Bedingung hinzufügen"
                },
                () => {
                    const numOfTabs = tabsBuilderMarkerStyle.build().value.tabs.value.length;
                    const uuid = self.crypto.randomUUID().toString();
                    refsMarkerStyleConditions[uuid.toString()] = { icon: ref(), iconColor: ref(), color: ref(), conditionField: ref(), conditionOperator: ref(), conditionValue: ref(), conditionFieldType: ref() }
                    addStyleConditionTab(uuid.toString(), numOfTabs);
                    refTabsMarkerStyleCurrent.value = numOfTabs;
                },
                {
                    class: "w-full"
                }
            )
            .build(),
        () => !refErrorMarkerStyleConditions.value,
        {
            onMount: () => {
                if (refStylingIcon.value === undefined) {
                    refStylingIcon.value = refButtonIcon.value;
                }
                if (refStylingColor.value === undefined) {
                    refStylingColor.value = refButtonColor.value;
                }
            }
        }
    )
    // STEP 6: Context Source
    stepper.addStep(
        {
            en: "Context",
            de: "Kontext"
        },
        "mdi-information",
        buildContainer()
            .addHeader({
                en: "Context",
                de: "Kontext"
            })
            .addDesc({
                en: "Specify a URL via which the context for the individual displayed map elements is to be obtained. This usually requires IDs or similar, which must be specified in the URL. Placeholders can be inserted for such variable values in the URL. You may use the button to do insert such wildcards.",
                de: "Gib eine URL an über die der Kontext für die einzelnen, dargestellten Kartenelemente bezogen werden soll. Üblicherweise sind dafür IDs oder dergleichen notwendig, die in der URL angegeben werden müssen. Für solche veränderlichen Werte in der URL können Platzhalter eingefügt werden. Benutze dafür die Schaltfläche."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addTextArea(
                        {
                            en: "Context URL",
                            de: "Kontext URL"
                        },
                        refContextRequestUrl,
                        {
                            disabled: refContextUseGeoDataAsSource,
                            error: refContextRequestError,
                            cols: 5,
                            rows: 2,
                            onChange: () => {
                                if (Array.isArray(refGeoDataPreview.value)) {
                                    const url = FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0]));
                                    fetchContextPreviewByUrl(url);
                                }
                            }
                        }
                    )
                    .addJsonPicker(
                        computed(() => Array.isArray(refGeoDataPreview.value) ? refGeoDataPreview.value[0] : undefined),
                        (event:any) => {
                            refContextRequestUrl.value = refContextRequestUrl.value ? refContextRequestUrl.value + StringUtils.buildZmRef(event.path) : StringUtils.buildZmRef(event.path) + "";
                            if (Array.isArray(refGeoDataPreview.value)) {
                                const url = FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0]));
                                fetchContextPreviewByUrl(url);
                            }
                        },
                        {
                            disabled: refContextUseGeoDataAsSource,
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addDesc({
                en: "If the data in the list of geodata is sufficient, it can be used as a source for the context instead.",
                de: "Wenn die Daten in der Liste der Geodaten ausreichend sind, können diese genutzt werden um als Quelle für den Kontext zu dienen."
            })
            .addCheckbox(
                {
                    en: "Use list of geo data as a source",
                    de: "Geodatenliste als Quelle nutzen"
                },
                refContextUseGeoDataAsSource,
                {
                    onChange: () => {
                        if (refContextUseGeoDataAsSource.value !== true && StringUtils.isURL(refContextRequestUrl.value)) {
                            fetchContextPreviewByUrl(DataUtils.containsWildcardOrConditional(refContextRequestUrl.value) ?
                                FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0])) : refContextRequestUrl.value);
                        } else {
                            refContextPreviewFetched.value = undefined;
                        }
                    }
                }
            )
            .addDesc({
                en: "In order to identify the context data in the geo list, choose path to a unique identifier (ID) in from the object:",
                de: "Um die Kontextobjekte in der Geodatenliste zu identifizieren, gib einen Pfad zu einem Attribut an, dass einen einzigartigen Wert (ID) beinhaltet:"
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Path to ID",
                            de: "Pfad zur ID"
                        },
                        refContextPathToId,
                        {
                            disabled: computed(() => !refContextUseGeoDataAsSource.value),
                            error: refContextPathToIdError,
                            cols: 3,
                            readonly: ref(true)
                        }
                    )
                    .addInput(
                        {
                            en: "Example ID Value",
                            de: "Beispiel ID-Wert"
                        },
                        refContextIdPreview,
                        {
                            disabled: computed(() => !refContextUseGeoDataAsSource.value),
                            error: refContextPathToIdError,
                            cols: 2,
                            readonly: ref(true)
                        }
                    )
                    .addJsonPicker(
                        refContextPreview,
                        (event: any) => {
                            refContextPathToId.value = event.path;
                        },
                        {
                            size: "large",
                            disabled: computed(() => !refContextUseGeoDataAsSource.value)
                        }
                    )
                    .build()
            )
            .addJsonArea(
                {
                    en: "Context Preview",
                    de: "Kontext Vorschau"
                },
                refContextPreview,
                {
                    loading: refContextPreviewLoading,
                    rows: 8
                }
            )
            .build(),
        () => {
            return refContextUseGeoDataAsSource.value ?
                !refContextPathToIdError.value :
                !refContextRequestError.value
        }
    )
    // STEP 6: Formatting
    const refFormattingTypes = ref([
        {
            title: Text.asString({ en: "Text", de: "Text" }),
            value: CONTEXT_TYPES.TEXT
        },
        {
            title: Text.asString({ en: "Table", de: "Tabelle" }),
            value: CONTEXT_TYPES.TABLE
        },
    ]);
    const refFormattingSelectedType = ref(data?.sources[0]?.formatting?.type ? data.sources[0].formatting.type : CONTEXT_TYPES.TEXT);
    const refsFormattingTextTemplates = DataUtils.createLangRefs();
    const formattingValue = data?.sources[0]?.formatting?.value;
    if (
        formattingValue && isText(formattingValue)
    ) {
        Object.values(LANGUAGE).forEach(language => {
            refsFormattingTextTemplates[language].value = formattingValue[language];
        });
    }
    const refsFormattingTextTemplatesError = Object.values(LANGUAGE).reduce((prev, language) => {
        return { ...prev, [language]: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "") }
    }, {} as Record<LANGUAGE, Ref<boolean>>)
    stepper.addStep(
        {
            en: "Formatting",
            de: "Formatierung"
        },
        "mdi-help",
        buildContainer()
            .addHeader({
                en: "Context Formatting",
                de: "Kontext Formatierung"
            })
            .addDesc({
                en: "Choose how to format the context that will be displayed when the button is clicked or hovered upon.",
                de: "Wähle die Formatierung aus, die für den Kontext der Daten verwendet werden soll. Der Kontext wird angezeigt wenn auf einen Datenpunkt geklickt wird oder die Maus darüber fährt."
            })
            .addSelect(
                {
                    en: "Formatting Type",
                    de: "Formatierungstyp"
                },
                refFormattingSelectedType,
                refFormattingTypes,
            )
            .addContainer(
                buildContainer()
                    .setOptions({
                        hidden: computed(() => refFormattingSelectedType.value !== "TABLE")
                    })
                    .addDesc({
                        en: "This formatting option is currently not available!",
                        de: "Diese Formattierungsvariante ist im Moment nicht verfügbar!"
                    })
                    .build()
            )
            .addTabs(
                buildTabs()
                    .setOptions({
                        hidden: computed(() => refFormattingSelectedType.value !== "TEXT"),
                        fixed: true
                    })
                    .addMultipleTabs(Object.values(LANGUAGE).map((language) => {
                        return {
                            title: Text.buildFromString(language.toUpperCase()).getDataObject(),
                            content: buildContainer()
                                .addTextEditor(
                                    {
                                        en: `Context (${language.toUpperCase()})`,
                                        de: `Kontext (${language.toUpperCase()})`
                                    },
                                    refsFormattingTextTemplates[language],
                                    {
                                        pickableData: refContextPreview
                                    }
                                )
                                .addPreviewContext(
                                    computed(() => {
                                        const title = refsButtonTitle[language].value;
                                        if (title) {
                                            return Text.buildFromString(title).getDataObject()
                                        }
                                        return undefined
                                    }),
                                    computed(() => refContextPreview.value ? Text.buildFromString(FetchWorkerUtils.replaceByMatrix(refsFormattingTextTemplates[language].value, DataUtils.unproxy(refContextPreview.value))).getDataObject() : undefined),
                                    refStylingIcon,
                                    refStylingColor
                                )
                                .build(),
                            options: {
                                prependIcon: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "" ? "mdi-pencil-circle" : "mdi-check-circle"),
                                error: refsFormattingTextTemplatesError[language],
                                color: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "" ? "#f62e36" : "#80cc28"),
                                colorInactive: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "" ? "#f62e36" : "#80cc28")
                            }
                        }
                    }))
                    .build()
            )
            .build(),
        () => refFormattingSelectedType.value === CONTEXT_TYPES.TEXT ? Object.values(LANGUAGE).reduce((prev, language) => prev && !refsFormattingTextTemplatesError[language].value, true) : false
    )
    const refAddButtonLoadingState = ref(false);
    // STEPPER Submit settings
    stepper
        .setOptions({
            submitLoading: refAddButtonLoadingState,
            submitTitle: options?.submitText ? options.submitText : {
                en: "Add Data",
                de: "Hinzufügen"
            },
            noHeader: true
        })
        .setSubmit(() => {
            refAddButtonLoadingState.value = true;
            try {
                // build name text
                const name: any = {};
                Object.values(LANGUAGE).forEach(language => {
                    name[language] = refsButtonTitle[language].value;
                });
                // build formatting
                const formatting: any = {};
                if (refFormattingSelectedType.value === CONTEXT_TYPES.TEXT) {
                    formatting["type"] = CONTEXT_TYPES.TEXT;
                    formatting["value"] = {};
                    Object.values(LANGUAGE).forEach(language => {
                        formatting.value[language] = refsFormattingTextTemplates[language].value;
                    });
                }
                if (refFormattingSelectedType.value === CONTEXT_TYPES.TABLE) {
                    formatting["type"] = CONTEXT_TYPES.TABLE;
                    formatting["value"] = {
                        columns: [],
                        rows: []
                    };
                    // TODO: add table formatting
                    // formatting.value.columns = refsContextFormattingTableColumns.value;
                    // formatting.value.rows = refsContextFormattingTableRows.value;
                }

                let contextRoot = "";
                if (refContextUseGeoDataAsSource.value && refContextPathToId.value) {
                    contextRoot = `${refGeoDataRoot.value}[${refContextPathToId.value}?${StringUtils.buildZmRef(refContextPathToId.value)}]`;
                }

                const getCoordinatesType = () => {
                    if (!refCoordinatesLatLonGeoJsonError.value && refCoordinatesLatLonGeoJson.value) {
                        const latLon = DataUtils.getValueByPath(refCoordinatesLatLonGeoJson.value, refGeoDataPreview.value[0]);
                        if (Array.isArray(latLon) && latLon.every(ll => typeof ll === "number")) {
                            return GEOJSON_TYPES.POINT
                        }
                        if (Array.isArray(latLon) && latLon.every(arr => Array.isArray(arr) && arr.every(ll => typeof ll === "number"))) {
                            return GEOJSON_TYPES.LINESTRING
                        }
                    }
                    return GEOJSON_TYPES.POINT
                }

                const createSwitch = (
                    defaultValue: any,
                    conditionKey: keyof typeof refsMarkerStyleConditions,
                    valueKey: keyof typeof refsMarkerStyleConditions
                ): string | i_switch | undefined => {
                    const relevantConditions = Object.values(refsMarkerStyleConditions).filter(
                        (c) => (c as any)[conditionKey].value !== defaultValue
                    );

                    if (relevantConditions.length > 0) {
                        return {
                            default: defaultValue,
                            cases: relevantConditions
                                .filter((c) => c.conditionValue.value !== undefined)
                                .map((c) => ({
                                    exp: StringUtils.createExpression(
                                        StringUtils.buildZmRef(c.conditionField.value as string),
                                        c.conditionOperator.value as string,
                                        c.conditionValue.value,
                                        {
                                            typeOperand1: c.conditionFieldType.value &&
                                            ["number", "string", "boolean"].includes(c.conditionFieldType.value)
                                                ? c.conditionFieldType.value
                                                : "string",
                                            typeOperand2: c.conditionFieldType.value &&
                                            ["number", "string", "boolean"].includes(c.conditionFieldType.value)
                                                ? c.conditionFieldType.value
                                                : "string"
                                        }
                                    ),
                                    value: (c as any)[valueKey].value
                                }))
                        } as i_switch;
                    }

                    return defaultValue;
                };

                const getIcon = (): string | i_switch | undefined =>
                    createSwitch(refStylingIcon.value, "icon", "icon");

                const getIconColor = (): string | i_switch | undefined =>
                    createSwitch(refStylingIconColor.value !== null ? refStylingIconColor.value : undefined, "iconColor", "iconColor");

                const getColor = (): string | i_switch | undefined =>
                    createSwitch(refStylingColor.value, "color", "color");

                console.log(refGeoDataUrl.value);
                const lat = `${refCoordinatesLatitude.value}`;
                const lon = `${refCoordinatesLongitude.value}`;

                const createdSources: Omit<ISource, "id">[] = [{
                    ...isUpdate ? { id: data?.sources[0].id } : {},
                    location: {
                        type: "HTTP",
                        url: refGeoDataUrl.value,
                        dataRoot: refGeoDataRoot.value
                    },
                    template: {
                        type: getCoordinatesType(),
                        name: Object.values(LANGUAGE).reduce((prev, language) => {
                            return { ...prev, [language]: refsCoordinatesName[language].value }
                        }, {}) as IText,
                        coordinates: !refCoordinatesLatLonGeoJsonError.value && refCoordinatesLatLonGeoJson.value ? StringUtils.buildZmRef(refCoordinatesLatLonGeoJson.value) : [
                            StringUtils.buildZmRef(lat),
                            StringUtils.buildZmRef(lon)
                        ],
                        swapCoordinates: refSwapCoordinates.value,
                        icon: getIcon(),
                        color: getColor(),
                        iconColor: getIconColor()
                    },
                    context: {
                        type: "HTTP",
                        url: refContextUseGeoDataAsSource.value ? refGeoDataUrl.value : refContextRequestUrl.value,
                        dataRoot: contextRoot
                    },
                    formatting: formatting
                }];

                const content: ICreateEntryDto = {
                    name: name as i_text,
                    idx: options?.data ? options.data.idx : 9999,
                    icon: refButtonIcon.value as string,
                    color: refButtonColor.value as string,
                    sources: createdSources,
                    functions: []
                };

                if (Object.values(refsButtonDesc).every(desc => desc.value !== undefined && desc.value !== "")) {
                    DataUtils.setValueByPath(content, "info.description", Object.values(LANGUAGE).reduce((prev, language) => {
                        return {
                            ...prev,
                            [language]: refsButtonDesc[language].value
                        }
                    }, {}));
                }
                if (StringUtils.isURL(refButtonSourceUrl.value)) {
                    DataUtils.setValueByPath(content, "info.link", refButtonSourceUrl.value);
                }

                if (log.willDebug()) {
                    log.debug(JSON.stringify(content));
                }

                submitCallback(content, refAddButtonLoadingState);
            } catch (e: any) {
                refAddButtonLoadingState.value = false;
                log.error(e.message);
                useSnackbar().error(new Text({
                    en: "An error occurred while processing the data!",
                    de: "Ein Fehler ist bei der Verarbeitung der Daten aufgetreten!"
                }).get())
            }
        })
    const dialog = buildDialog()
        .setTitle(options?.title ? options.title : {
            en: "Create Data Entry",
            de: "Daten hinzufügen"
        })
        .setWidth(600)
        .setContent(
            buildContainer()
                .addStepper(stepper.build())
                .build()
        )
        .build()
    useDialog().set(dialog);
    useDialog().open();
}
