export enum CONTEXT_TYPES {
    TEXT = "TEXT",
    TABLE = "TABLE",
    CONTAINER = "CONTAINER"
}

export enum SORT_TYPES {
    UP = "UP",
    DOWN = "DOWN"
}

export enum MAP_TEMPLATE {
    POLYLINE = "POLYLINE",
    MARKER = "MARKER"
}

export enum GeoJsonTypes {
    POINT = "Point",
    LINESTRING = "LineString",
    POLYGON = "Polygon",
    MULTIPOLYGON = "MultiPolygon",
}

export enum PANEL_TYPES {
    ROUTER = "ROUTER",
    DATA = "DATA"
}

export enum MAPPING_TYPE {
    ADD = "ADD",
    MULTIPLY = "MULTIPLY",
    SUBTRACT = "SUBTRACT",
    APPEND = "APPEND",
    REMOVE = "REMOVE",
    CUSTOM = "CUSTOM"
}

export enum USER_ROLES {
    ADMIN = "admin",
    CLERK = "clerk",
    USER = "user",
    FIRE_DEPARTMENT = "fire_department"
}
