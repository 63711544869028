import {Base} from "@/Base";
import {useFetchWorker} from "@/services/fetch.worker.service";
import {DataUtils} from "@/utils/data.utils";
import {isDataPanel, isRouterPanel} from "@/types/typeguards/general.guards";
import {
    i_panelDto,
    i_validator
} from "@/types/interfaces/structure.interfaces";
import {useStore} from "@/services/store.service";
import {MARKER_STATE, SID} from "@/types/enums/frontend.enums";
import {ref, Ref} from "vue";
import {i_axiosOptions} from "@/types/interfaces/workers.interfaces";
import {useKeycloak} from "@/services/keycloak.service";
import {isWorkerAxiosSuccess} from "@/types/typeguards/frontend.guards";
import {USER_ROLES} from "@/types/enums/general.enums";
import {_VUETIFY_COLORS} from "@/assets/colors";
import {usePolling} from "@/services/polling.service";
import {useMap} from "@/services/map.service";
import {
    ICreateCategoryDto, ICreateEntryDto, ICreateGroupDto,
    IEntryDto,
    IGroupDto, IIsochroneOptions,
    IUpdateCategoryDto, IUpdateEntryDto, IUpdateGroupDto
} from "@/types/interfaces/dto.interfaces";
import {isProxyData} from "@/types/typeguards/proxy.guards";
import {IProxyCategory, IProxyData, IProxyEntry} from "@/types/interfaces/proxy.interfaces";
import {AxiosRequestConfig} from "axios";
import {FeatureCollection} from "@turf/turf";

class ConnectionService extends Base {
    private unproxiedRequests: Ref<any>;
    private data: Ref<IProxyData | null>;

    constructor() {
        super(ConnectionService.name);
        this.unproxiedRequests = ref(null);
        this.data = ref(null);
    }

    public getProxiedCategory(id: string) {
        return this.data.value?.groups.reduce((prev, curr) => { return [...prev, ...curr.categories] }, [] as IProxyCategory[]).find(c => c.id === id);
    }

    public getProxiedGroup(id: string) {
        return this.data.value?.groups.find(g => g.id === id);
    }

    public addGroup(data: ICreateGroupDto) {
        return this.request("POST", "/groups", { data: DataUtils.unproxy(data) });
    }

    public addCategory(data: ICreateCategoryDto, parentGroupId: string) {
        return this.request("POST", `/groups/${parentGroupId}/categories`, { data: DataUtils.unproxy(data) });
    }

    public addEntry(data: ICreateEntryDto, parentGroupId: string, parentCategoryId: string) {
        return this.request("POST", `/groups/${parentGroupId}/categories/${parentCategoryId}/entries`, { data: DataUtils.unproxy(data) });
    }

    public updateGroup(data: IUpdateGroupDto, groupId: string) {
        return this.request("PUT", `/groups/${groupId}`, { data: DataUtils.unproxy(data) });
    }

    public updateCategory(data: IUpdateCategoryDto, parentGroupId: string, categoryId: string) {
        return this.request("PUT", `/groups/${parentGroupId}/categories/${categoryId}`, { data: DataUtils.unproxy(data) });
    }

    public updateEntry(data: IUpdateEntryDto, parentGroupId: string, parentCategoryId: string, entryId: string) {
        return this.request("PUT", `/groups/${parentGroupId}/categories/${parentCategoryId}/entries/${entryId}`, { data: DataUtils.unproxy(data) });
    }

    public deleteGroup(groupId: string) {
        return this.request("DELETE", `/groups/${groupId}`);
    }

    public deleteCategory(parentGroupId: string, categoryId: string) {
        return this.request("DELETE", `/groups/${parentGroupId}/categories/${categoryId}`);
    }

    public deleteEntry(parentGroupId: string, parentCategoryId: string, entryId: string) {
        return this.request("DELETE", `/groups/${parentGroupId}/categories${parentCategoryId}/entries/${entryId}`);
    }

    public getGroup(groupId: string) {
        return this.request("GET", `/groups/${groupId}`);
    }

    public getCategory(parentGroupId: string, categoryId: string) {
        return this.request("GET", `/groups/${parentGroupId}/categories/${categoryId}`);
    }

    public getEntry(parentGroupId: string, parentCategoryId: string, entryId: string) {
        return this.request("GET", `/groups/${parentGroupId}/categories/${parentCategoryId}/entries/${entryId}`);
    }

    public postFunction(parentGroupId: string, parentCategoryId: string, entryId: string, functionId: string, functionData: any) {
        return this.request("POST", `/groups/${parentGroupId}/categories/${parentCategoryId}/entries/${entryId}/functions/${functionId}`, { data: functionData });
    }

    public setupProxyData(): Promise<any> {
        return this.request("GET", `/proxy-data`)
            .then((response: any) => {
                if (!isProxyData(response.data)) {
                    console.error(response.data);
                    throw new Error("Invalid data format for exchange data!");
                }
                this.data.value = response.data;
                return response;
            });
    }

    public proxyUrl(url: string, options?: i_axiosOptions): Promise<any> {
        return this.request("GET", `/proxy-url`, {
            timeout: 10000,
            headers: {ProxyTarget: url}
        }, {
            ...options,
            basePath: "/api/v2"
        })
            .then((response: any) => {
                return response.data;
            });
    }

    public refProxiedData() {
        return this.data;
    }

    public getProxiedEntryById(id: string): IProxyEntry | undefined {
        const data = this.data.value;
        if (data !== null) {
            for (const group of data.groups) {
                for (const category of group.categories) {
                    for (const entry of category.entries) {
                        if (entry.id === id) {
                            return entry;
                        }
                    }
                }
            }
        }

        return undefined;
    }

    public getIsochrone(lat: number, lon: number, options?: IIsochroneOptions): Promise<FeatureCollection | undefined> {
        return this.request(
            "POST",
            "/isochrones",
            {
                data: {
                    lat, lon, options
                }
            }
        ).then((res) => {
            return res.data as FeatureCollection;
        }).catch((error: any) => {
            this.log.error(`An error occurred while fetching the isochrone for Point [${lat}, ${lon}]: ${error.message}`);
            return undefined
        });
    }

    private request(method: "POST" | "PUT" | "GET" | "DELETE", path: string, config?: Partial<AxiosRequestConfig>, options?: i_axiosOptions) {
        options = options ? options : {};
        options.auth = options.auth == undefined ? true : options.auth;
        return useFetchWorker().axiosRequest({
            method: method,
            baseURL: options?.basePath ?? "/api/v2/data/map",
            url: path,
            ...config !== undefined ? config : {},
            timeout: 10 * 1000
        }, options)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error((response as { status: number; message: string }).message);
                }
                return response as { status: number, data: any };
                // return useConnection().setupProxyData()
                //     .then(() => {
                //         return response as { status: number, data: any };
                //     });
            })
    }

    public addContent(path: string, content: any, validator: i_validator) {
        const addContentDto = {
            path: path,
            payload: content,
            validator: validator
        };
        return useFetchWorker().axiosRequest({
            method: "POST",
            url: "/api/map/data",
            data: DataUtils.unproxy(addContentDto),
            timeout: 5000
        }, { auth: true })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error((response as { status: number; message: string }).message);
                }
                return useConnection().setupRequest()
                    .then(() => {
                        return response;
                    });
            })
    }

    public updateContent(path: string, content: any, validator: i_validator) {
        const updateContentDto = {
            path: path,
            payload: content,
            validator: validator
        };
        return useFetchWorker().axiosRequest({
            method: "PUT",
            url: "/api/map/data",
            data: DataUtils.unproxy(updateContentDto),
            timeout: 5000
        }, { auth: true })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error((response as { status: number; message: string }).message);
                }
                return useConnection().setupRequest()
                    .then(() => {
                        return response;
                    });
            })
    }

    public removeContent(path: string, validator: i_validator) {
        const removeContentDto: { path: string, validator: i_validator } = {
            path: path,
            validator: validator
        };
        return useFetchWorker().axiosRequest({
            method: "DELETE",
            url: "/api/map/data",
            data: DataUtils.unproxy(removeContentDto),
            timeout: 5000
        }, { auth: true })
            .then((response) => {
                if (response.status >= 400) {
                    throw response as { status: number, message: string };
                }
                return useConnection().setupRequest()
                    .then(() => {
                        return response;
                    });
            });
    }

    public restoreState(reason: string, timestamp: string) {
        const restoreContentDto = {
            reason: reason,
            timestamp: timestamp
        }
        return useFetchWorker().axiosRequest({
            method: "POST",
            url: "/api/map/backups",
            data: restoreContentDto,
            timeout: 5000
        }, { auth: true })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error((response as { status: number; message: string }).message);
                }
                return (response as { status: number, data: any }).data;
            });
    }

    public getStates() {
        return useFetchWorker().axiosRequest({
            method: "GET",
            url: "/api/map/backups",
            timeout: 5000
        }, { auth: true })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error((response as { status: number; message: string }).message);
                }
                return (response as { status: number, data: any }).data as { reason: string, timestamp: string }[];
            });
    }

    public proxyRequest(url: string, options?: i_axiosOptions) {
        options = options ? options : {};
        options.auth = options.auth == undefined ? true : options.auth;
        return useFetchWorker().axiosRequest({
            method: "GET",
            url: "/proxy",
            timeout: 10000,
            headers: {ProxyTarget: url}
        }, options)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error((response as { status: number; message: string }).message);
                }
                return (response as { status: number, data: any }).data;
            });
    }

    public getUnproxiedRequests() {
        return this.unproxiedRequests.value;
    }

    public setupRequest(): Promise<i_panelDto[]> {
        return useFetchWorker().axiosRequest({
            method: "GET",
            url: "/api/map/data",
            timeout: 10000,
        }, { auth: true })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error((response as { status: number; message: string }).message);
                }
                if ("data" in response && Array.isArray(response.data) && response.data.every((obj: any) => isDataPanel(obj) || isRouterPanel(obj))) {
                    let data = JSON.stringify(response.data);
                    if (useKeycloak().hasRole(USER_ROLES.CLERK) || useKeycloak().hasRole(USER_ROLES.ADMIN)) {
                        return useFetchWorker().axiosRequest({
                            method: "GET",
                            url: "/api/map/unproxied",
                            timeout: 5000,
                        }, { auth: true })
                            .then((unproxied) => {
                                if (isWorkerAxiosSuccess(unproxied)) {
                                    let unproxiedData = JSON.stringify(unproxied.data);
                                    Object.keys(_VUETIFY_COLORS).forEach((color: string) => {
                                        unproxiedData = unproxiedData.split(`"${color}"`).join(`"${_VUETIFY_COLORS[color]}"`)
                                    });
                                    this.unproxiedRequests.value = JSON.parse(unproxiedData);
                                }
                                // preprocess and replace vuetify colors in data object
                                Object.keys(_VUETIFY_COLORS).forEach((color: string) => {
                                    data = data.split(`"${color}"`).join(`"${_VUETIFY_COLORS[color]}"`)
                                });
                                return data;
                                //return JSON.parse(data) as i_panelDto[];
                                //return response.data as (i_dataPanelDto | i_routerPanelDto)[];
                            })
                    }
                    // preprocess and replace vuetify colors in data object
                    Object.keys(_VUETIFY_COLORS).forEach((color: string) => {
                        data = data.split(`"${color}"`).join(`"${_VUETIFY_COLORS[color]}"`)
                    });
                    return data;
                    //return JSON.parse(data) as i_panelDto[];
                } else {
                    this.log.warn("Received invalid server data, keep current data structure!");
                    return useStore().get(SID.MAP_DATA, "[]");
                }
            })
            .then((mapData) => {
                if (mapData !== useStore().get(SID.MAP_DATA, "")) {
                    const buttonStates = useStore().get(SID.MARKER_BUTTON_STATE, {} as Record<string, MARKER_STATE>);
                    const activeButtonIds = Object.keys(buttonStates).filter(key => buttonStates[key] === MARKER_STATE.ACTIVE);
                    for (const id of activeButtonIds) {
                        this.log.debug(`check if element with id '${id}' still exists: ${mapData.includes(`"${id}"`)}`);
                        if (!mapData.includes(`"${id}"`)) {
                            if (usePolling().isPolling(id)) {
                                usePolling().stopPoll(id);
                            }
                            useMap().removeLayer(id);
                            useMap().removeLayer(id + ":labels");
                        }
                    }
                    useStore().set(SID.MAP_DATA, mapData);
                }
                return JSON.parse(mapData);
            })
    }
}

const connectionService = new ConnectionService();

export const useConnection = () => {
    return connectionService;
}
