import { Geometry, Point, LineString, Polygon, MultiPoint, MultiLineString, MultiPolygon, GeometryCollection } from 'geojson';

// Type guard for Point
export const isPoint = (geometry: any): geometry is Point =>
    geometry &&
    typeof geometry === "object" &&
    geometry.type === "Point" &&
    Array.isArray(geometry.coordinates) &&
    geometry.coordinates.length === 2 &&
    typeof geometry.coordinates[0] === "number" &&
    typeof geometry.coordinates[1] === "number";

// Type guard for LineString
export const isLineString = (geometry: any): geometry is LineString =>
    geometry &&
    typeof geometry === "object" &&
    geometry.type === "LineString" &&
    Array.isArray(geometry.coordinates) &&
    geometry.coordinates.every(
        (coord: any) => Array.isArray(coord) &&
            coord.length === 2 &&
            typeof coord[0] === "number" &&
            typeof coord[1] === "number"
    );

// Type guard for Polygon
export const isPolygon = (geometry: any): geometry is Polygon =>
    geometry &&
    typeof geometry === "object" &&
    geometry.type === "Polygon" &&
    Array.isArray(geometry.coordinates) &&
    geometry.coordinates.every(
        (ring: any) => Array.isArray(ring) &&
            ring.every(
                (coord: any) => Array.isArray(coord) &&
                    coord.length === 2 &&
                    typeof coord[0] === "number" &&
                    typeof coord[1] === "number"
            )
    );

// Type guard for MultiPoint
export const isMultiPoint = (geometry: any): geometry is MultiPoint =>
    geometry &&
    typeof geometry === "object" &&
    geometry.type === "MultiPoint" &&
    Array.isArray(geometry.coordinates) &&
    geometry.coordinates.every(
        (coord: any) => Array.isArray(coord) &&
            coord.length === 2 &&
            typeof coord[0] === "number" &&
            typeof coord[1] === "number"
    );

// Type guard for MultiLineString
export const isMultiLineString = (geometry: any): geometry is MultiLineString =>
    geometry &&
    typeof geometry === "object" &&
    geometry.type === "MultiLineString" &&
    Array.isArray(geometry.coordinates) &&
    geometry.coordinates.every(
        (line: any) => Array.isArray(line) &&
            line.every(
                (coord: any) => Array.isArray(coord) &&
                    coord.length === 2 &&
                    typeof coord[0] === "number" &&
                    typeof coord[1] === "number"
            )
    );

// Type guard for MultiPolygon
export const isMultiPolygon = (geometry: any): geometry is MultiPolygon =>
    geometry &&
    typeof geometry === "object" &&
    geometry.type === "MultiPolygon" &&
    Array.isArray(geometry.coordinates) &&
    geometry.coordinates.every(
        (polygon: any) => Array.isArray(polygon) &&
            polygon.every(
                (ring: any) => Array.isArray(ring) &&
                    ring.every(
                        (coord: any) => Array.isArray(coord) &&
                            coord.length === 2 &&
                            typeof coord[0] === "number" &&
                            typeof coord[1] === "number"
                    )
            )
    );

// Type guard for GeometryCollection
export const isGeometryCollection = (geometry: any): geometry is GeometryCollection =>
    geometry &&
    typeof geometry === "object" &&
    geometry.type === "GeometryCollection" &&
    Array.isArray(geometry.geometries) &&
    geometry.geometries.every(isValidGeometry);

// General geometry validator
export const isValidGeometry = (geometry: any): geometry is Geometry => {
    return isPoint(geometry) ||
        isLineString(geometry) ||
        isPolygon(geometry) ||
        isMultiPoint(geometry) ||
        isMultiLineString(geometry) ||
        isMultiPolygon(geometry) ||
        isGeometryCollection(geometry);
};
