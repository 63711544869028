import Keycloak, { KeycloakInstance, KeycloakInitOptions } from 'keycloak-js';
import {ref, Ref} from "vue";
import axios from "axios";
import {useFetchWorker} from "@/services/fetch.worker.service";
import {Base} from "@/Base";
import {i_keycloakConfig} from "@/types/interfaces/frontend.interfaces";

interface i_keycloakUserProfile {
    sub: string;
    email_verified: boolean;
    name: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    email: string;
}

const isKeycloakUserInfo = (obj:any): obj is i_keycloakUserProfile => {
    return obj && typeof obj === 'object' &&
        'sub' in obj && typeof obj.sub === 'string' &&
        'email_verified' in obj && typeof obj.email_verified === 'boolean' &&
        'name' in obj && typeof obj.name === 'string' &&
        'preferred_username' in obj && typeof obj.preferred_username === 'string' &&
        'given_name' in obj && typeof obj.given_name === 'string' &&
        'family_name' in obj && typeof obj.family_name === 'string' &&
        'email' in obj && typeof obj.email === 'string';
}

class KeycloakService extends Base {
    private keycloak: KeycloakInstance | undefined;
    private tokenRefreshTimeout: number | undefined;
    private storage: Storage;
    private keycloakUserInfo: Ref<i_keycloakUserProfile | undefined>;
    private keycloakUserRoles: Ref<string[]>;

    constructor() {
        super(KeycloakService.name);
        this.keycloakUserInfo = ref(undefined);
        this.keycloakUserRoles = ref([]);
        this.storage = window.localStorage; //config.useLocalStorage ? localStorage : sessionStorage;
    }

    public async init(config: i_keycloakConfig): Promise<boolean> {
        try {
            this.keycloak = new Keycloak({
                url: "/keycloak",
                realm: config.realm,
                clientId: config.clientId,
            });

            const token = this.storage.getItem('kc_token');
            const refreshToken = this.storage.getItem('kc_refreshToken');
            const idToken = this.storage.getItem('kc_idToken');

            const initOptions: KeycloakInitOptions = {
                onLoad: 'login-required',
                checkLoginIframe: false,
                enableLogging: true,
                token: token ? token : undefined,
                refreshToken: refreshToken ? refreshToken : undefined,
                idToken: idToken ? idToken : undefined
            };

            const authenticated = await this.keycloak.init(initOptions);

            if (authenticated) {
                await this.loadUserInfo();
                this.storeTokens();
                this.refreshToken();
            } else {
                this.clearStoredTokens();
            }

            return authenticated;
        } catch (error: any) {
            this.log.error(`Failed to initialize Keycloak: ${error.message}`);
            return false;
        }
    }

    private async loadUserInfo() {
        if (this.keycloak) {
            const userInfo = await this.keycloak.loadUserInfo();
            if (isKeycloakUserInfo(userInfo)) {
                this.keycloakUserInfo.value = userInfo;
            } else {
                this.log.error(`Cannot retrieve valid user info: ${userInfo}`);
            }
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
        }
    }

    private refreshToken(): void {
        if (this.keycloak) {
            if (this.tokenRefreshTimeout) {
                clearTimeout(this.tokenRefreshTimeout);
            }
            const timespan = (this.keycloak?.tokenParsed?.exp ? this.keycloak.tokenParsed.exp * 1000 : 0) - Date.now() - (30 * 1000);
            this.tokenRefreshTimeout = window.setTimeout(() => {
                this.keycloak?.updateToken(55).then((refreshed: any) => {
                    this.log.debug(`Token was refreshed: ${refreshed}`);
                    if (refreshed) {
                        this.storeTokens();
                        this.refreshToken();
                    }
                }).catch((error: any) => {
                    this.refreshToken();
                    this.log.error(`Failed to refresh token ${error}, retry in 3 seconds...`);
                });
            }, timespan > 0 ? timespan : 3000);
        }
    }

    public getToken(): string | undefined {
        if (this.keycloak) {
            return this.keycloak.token;
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
            return undefined;
        }
    }

    public hasRole(role: string): boolean {
        if (this.keycloak) {
            return this.keycloakUserRoles.value.includes(role);
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
            return false;
        }
    }

    public getRoles() {
        if (this.keycloak) {
            return this.keycloakUserRoles.value;
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
            return [];
        }
    }

    public isPermitted(permission: string | string[]) {
        if (this.keycloak) {
            if (Array.isArray(permission)) {
                return permission.some(p => this.hasRole(p));
            } else if (typeof permission === "string") {
                return this.hasRole(permission);
            } else if (this.getToken()) {
                return true;
            }
        }
        return false;
    }

    public getUserInfo(): i_keycloakUserProfile | undefined {
        return this.keycloakUserInfo.value;
    }

    public login() {
        if (this.keycloak) {
            this.keycloak.login();
        }
    }

    public async logout(): Promise<void> {
        this.keycloakUserInfo.value = undefined;
        this.keycloakUserRoles.value = [];
        if (this.keycloak) {
            try {
                await this.keycloak.logout();
                this.clearStoredTokens();
                if (this.tokenRefreshTimeout) {
                    clearTimeout(this.tokenRefreshTimeout);
                }
            } catch (error) {
                this.log.error(`Failed to logout ${error}`);
            }
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
        }
    }

    private storeTokens(): void {
        if (this.keycloak?.token && this.keycloak?.refreshToken && this.keycloak?.idToken) {
            const roles = this.keycloak?.tokenParsed?.realm_access?.roles;
            this.keycloakUserRoles.value = roles ? roles : [];
            this.storage.setItem('kc_token', this.keycloak.token);
            this.storage.setItem('kc_refreshToken', this.keycloak.refreshToken);
            this.storage.setItem('kc_idToken', this.keycloak.idToken);
            useFetchWorker().setAccessToken(this.keycloak.token).then(() => {
                this.log.debug("Access token passed to worker!");
            }).catch((err) => {
                this.log.error(`Cannot pass access token to worker: ${err}`);
            });
        }
    }

    private clearStoredTokens(): void {
        this.storage.removeItem('kc_token');
        this.storage.removeItem('kc_refreshToken');
        this.storage.removeItem('kc_idToken');
    }

    public async authenticatedRequest(method: "GET" | "POST", url: string, body?: any) {
        return axios.request({
            url,
            method,
            data: body,
            headers: {
                Authorization: `Bearer ${this.getToken()}`
            }
        })
    }
}

const keycloakService = new KeycloakService();
export const useKeycloak = () => {
    return keycloakService;
}
