import "leaflet";
import "leaflet.markercluster";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import {Base} from "@/Base";
import {createApp} from "vue";
import {vuetify} from "@/vuetify.config";
import ZmMapLabel from "@/components/LeafletMap/zm-map-label.vue";
import {LID, MDI, SID} from "@/types/enums/frontend.enums";
import {Text} from "@/utils/text";
import {i_route, i_routeSegment} from "@/types/interfaces/routing.interfaces";
import {motColors, motIcons} from "@/types/lookups";
import {
    i_context,
    i_contextScreenData,
    i_label,
    i_mapObject,
    i_marker,
    i_poi,
    i_polyline,
    i_position
} from "@/types/interfaces/frontend.interfaces";
import {CONTEXT_TYPES, MAP_TEMPLATE} from "@/types/enums/general.enums";
import {useStore} from "@/services/store.service";
import {StringUtils} from "@/utils/string.utils";
import {useFetchWorker} from "@/services/fetch.worker.service";
import ZmMapobjectContext from "@/components/LeafletMap/zm-mapobject-context.vue";
import ZmMarkerIcon from "@/components/LeafletMap/zm-marker-icon.vue";
import {useGeocoder} from "@/services/geocoder.service";
import {usePolling} from "@/services/polling.service";
import {
    IBuildLabelOptions,
    IBuildLayerOptions,
    IBuildMarkerOptions,
    IBuildPolygonOptions,
    IContextScreenData, ILayerOptions,
    IMapObject,
    IMarker,
    IPolygon,
    IPolyline,
    IPosition,
    TMapObject
} from "@/types/interfaces/map.interfaces";
import {TFormatting} from "@/types/interfaces/dto.interfaces";
import {Feature, union, lineString} from "@turf/turf";
import * as turf from "@turf/turf";
import {MultiPolygon, Polygon} from "geojson";

const L = window['L'];

class MapService extends Base {
    private mapInstance: L.Map | undefined;
    private layerGroups: Record<string, L.LayerGroup>;
    private childLayersOfLayer: Record<string, string[]>;
    private polylines: Record<string, L.Polyline>;
    private labels: Record<string, L.Marker>;
    private currentPopup: L.Popup | undefined;
    private mapObjectData: { layer: L.Layer, data: any }[];
    // @ts-ignore
    private clusterGroup: L.MarkerClusterGroup;
    private renderer: L.Canvas;

    constructor() {
        super(MapService.name);
        this.layerGroups = {};
        this.childLayersOfLayer = {};
        this.polylines = {};
        this.labels = {};
        this.mapObjectData = [];
        this.clusterGroup = L.markerClusterGroup();
        this.renderer = L.canvas({ padding: 0.5 });
    }

    /**
     * Sets the Leaflet map instance for the MapService.
     *
     * @param map The Leaflet map instance to set.
     */
    public setMap(map: L.Map) {
        this.log.debug("Set mapInstance for MapService!");
        this.mapInstance = map;
        this.clusterGroup = L.markerClusterGroup({
            iconCreateFunction: (cluster: any) => {
                return L.divIcon({
                    html: '<div class="flex items-center justify-center w-10 h-10 bg-white hover:bg-hellgrau shadow-lg hover:shadow-xl rounded-full group"><p class="font-bold group-hover:text-gelbgruen-100">' + cluster.getChildCount() + '</p></div>',
                    className: 'noWhiteBox'
                });
            },
            disableClusteringAtZoom: 16,
            zoomToBoundsOnClick: true,
            //polygonOptions: {opacity: 0, fill: false},
            showCoverageOnHover: false,
            maxClusterRadius: 100,
            spiderfyOnMaxZoom: false,
            spiderLegPolylineOptions: { weight: 0.0, color: '#222', opacity: 0.0 },
            chunkedLoading: true,
        }).addTo(this.mapInstance);
    }

    /**
     * Checks if the Leaflet map instance has been set.
     *
     * @returns True if the map instance is set, otherwise false.
     */
    public mapIsSet() {
        return this.mapInstance !== undefined;
    }

    /**
     * Sets a context marker at the specified position on the map.
     *
     * @param title The title of the context marker.
     * @param position The position of the context marker.
     */
    public setContextMarker(title: string, position: i_position) {
        const contextMarker: i_marker = {
            id: "zm-context-marker",
            type: MAP_TEMPLATE.MARKER,
            name: title,
            coordinates: [position.lat, position.lng],
            icon: MDI.INFO,
            iconColor: "white",
            color: "orange",
            data: {},
            context: {
                formatting: {
                    type: CONTEXT_TYPES.TEXT,
                    value: {
                        en: title,
                        de: title
                    }
                }
            }
        }
        this.removeLayer(LID.CONTEXT_MARKER);
        this.setLayersForGroup([this.buildMarkerLegacy(contextMarker)], LID.CONTEXT_MARKER);
    }

    /**
     * Builds Leaflet markers based on the provided marker data.
     *
     * @param markers Array of marker data objects.
     * @returns Array of built Leaflet markers.
     */
    public buildMarkersLegacy(markers: i_marker[]) {
        const lMarkers: L.Marker[] = [] as L.Marker[];
        markers.forEach(m => {
            lMarkers.push(this.buildMarkerLegacy(m));
        });
        return lMarkers;
    }

    /**
     * Builds Leaflet polylines based on the provided polyline data.
     *
     * @param polylines Array of polyline data objects.
     * @returns Array of built Leaflet polylines.
     */
    public buildPolylinesLegacy(polylines: i_polyline[]) {
        const lPolylines = [] as L.Polyline[];
        polylines.forEach(p => {
            lPolylines.push(this.buildPolylineLegacy(p));
        })
        return lPolylines;
    }

    /**
     * Builds Leaflet markers based on the provided marker data.
     *
     * @param markers Array of marker data objects.
     * @param layerId Layer to put the markers on.
     * @param options Build options
     * @returns Array of built Leaflet markers.
     */
    public buildMarkers(markers: IMarker[], layerId: string, options?: IBuildLayerOptions) {
        const lMarkers: L.Marker[] = [] as L.Marker[];
        markers.forEach(m => {
            const lMarker = this.buildMarker(m, options);
            this.mapObjectData.push({ layer: lMarker, data: m.data });
            lMarkers.push(lMarker);
        });
        this.addLayers(lMarkers, layerId, { ...options?.parentLayerId ? { parentLayerId: options.parentLayerId } : {} });
        return lMarkers;
    }

    /**
     * Builds Leaflet polygons based on the provided polygon data.
     *
     * @param polygons Array of polygon data objects.
     * @param layerId Layer to put the polygons on.
     * @param options (Optional) Build options.
     * @returns Array of built Leaflet polygons.
     */
    public buildPolygons(polygons: IPolygon[], layerId: string, options?: IBuildPolygonOptions) {
        const lPolygons: (L.Polygon | L.GeoJSON)[]  = [] as (L.Polygon | L.GeoJSON)[];
        polygons.forEach(polygon => {
            const lPolygon = this.buildPolygon(polygon, options);
            if (options?.unify) {
                const geoPolygon = lPolygon.toGeoJSON();
                const currentPolygons = this.getLayerGroup(layerId);
                const combined = currentPolygons
                    .map(p => p.toGeoJSON())
                    .reduce((p, c) => {
                        if (
                            (p.type === "Feature" && (p.geometry.type === "MultiPolygon" || p.geometry.type === "Polygon")) ||
                            (p.type === "FeatureCollection" && p.features.every(f => f.geometry.type === "MultiPolygon" || f.geometry.type === "Polygon"))
                        ) {
                            const united = union(
                                p.type === "FeatureCollection" ? p.features[0] as Feature<Polygon | MultiPolygon> : p as Feature<Polygon | MultiPolygon>,
                                c.type === "FeatureCollection" ? c.features[0] as Feature<Polygon | MultiPolygon> : c as Feature<Polygon | MultiPolygon>
                            );
                            if (united !== null) {
                                return united
                            }
                        }
                        return c;
                    }, geoPolygon as Feature<Polygon | MultiPolygon, any>);
                const combinedGeoPolygon = new L.GeoJSON(combined);
                combinedGeoPolygon.setStyle({ color: polygon.color });
                if (!options?.noClick) {
                    combinedGeoPolygon.on("click", (event) => {
                        useStore().set(SID.DRAWER_STATE, true);
                        useStore().set(SID.CONTEXT_STATE, true);
                        this.setContextAndRefreshInterval(polygon, {
                            lat: combinedGeoPolygon.getBounds().getCenter().lat,
                            lng: combinedGeoPolygon.getBounds().getCenter().lng
                        }, LID.CONTEXT_DATA);
                        L.DomEvent.stopPropagation(event);
                    });
                }
                if (!options?.noHover) {
                    combinedGeoPolygon.on("mouseover", () => {
                        if (this.currentPopup === undefined) {
                            this.showPopup(combinedGeoPolygon.getBounds().getCenter());
                            this.setContextAndRefreshInterval(polygon, {
                                lat: combinedGeoPolygon.getBounds().getCenter().lat,
                                lng: combinedGeoPolygon.getBounds().getCenter().lng
                            }, LID.CONTEXT_DATA_POPUP);
                        }
                    });
                    lPolygon.on("mouseout", () => {
                        this.closePopup();
                        usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
                    });
                }
                if (currentPolygons.length === 0) {
                    lPolygons.push(combinedGeoPolygon);
                } else {
                    lPolygons[0] = combinedGeoPolygon;
                }
            } else {
                lPolygons.push(lPolygon);
            }
            this.mapObjectData.push({ layer: lPolygon, data: polygon.data });
        });
        if (options?.unify) {
            this.removeLayer(layerId);
        }
        this.addLayers(lPolygons, layerId, { ...options?.parentLayerId ? { parentLayerId: options.parentLayerId } : {} });
        if (options?.label) {
            const labels = [] as L.Layer[];
            const buildLabelsOnEdge = (latLngs: L.LatLng[] | L.LatLng[][] | L.LatLng[][][], color: string) => {
                if (Array.isArray(latLngs) && (latLngs as any[]).every((latLng: any) => latLng instanceof L.LatLng)) {
                    const leftMostPoint = this.getLeftMostPointOfLatLngs(latLngs as L.LatLng[])
                    labels.push(this.buildLabel(
                        [ leftMostPoint.lat, leftMostPoint.lng ],
                        {
                            content: options.label,
                            icon: options?.labelIcon,
                            color
                        }
                    ))
                } else if (Array.isArray(latLngs) && (latLngs as any[]).every((subLatLngs: any[]) => subLatLngs.every(latLng => latLng instanceof L.LatLng))) {
                    // only do first polygon of a multipolygon, since the following polygons are the holes
                    buildLabelsOnEdge((latLngs as L.LatLng[][])[0], color);
                } else {
                    (latLngs as L.LatLng[][]).forEach(latLng => {
                        buildLabelsOnEdge(latLng, color);
                    })
                }
            };
            lPolygons.forEach(polygon => {
                if (polygon instanceof L.Polygon) {
                    buildLabelsOnEdge(polygon.getLatLngs(), polygon.options.color ?? "#000");
                } else {
                    polygon.getLayers().forEach(subpolygon => {
                        if (subpolygon instanceof L.Polygon) {
                            buildLabelsOnEdge(subpolygon.getLatLngs(), subpolygon.options.color ?? "#000");
                        }
                    });
                }
            })
            this.addLayers(labels, layerId + "-labels", { unclustered: true, parentLayerId: layerId });
        }
        return lPolygons;
    }

    private getLeftMostPointOfLatLngs(latLngs: L.LatLng[]): L.LatLng {
        return latLngs.reduce((leftmost, point) => point.lng < leftmost.lng ? point : leftmost, latLngs[0]);
    }

    /**
     * Builds Leaflet polylines based on the provided polyline data.
     *
     * @param polylines Array of polyline data objects.
     * @param layerId Layer to put the polygons on.
     * @param options Build options
     * @returns Array of built Leaflet polylines.
     */
    public buildPolylines(polylines: IPolyline[], layerId: string, options?: IBuildLayerOptions) {
        const lPolylines = [] as L.Polyline[];
        const lLabels = [] as L.Marker[];
        polylines.forEach(p => {
            const lPolyline = this.buildPolyline(p, options);
            lPolylines.push(lPolyline);
            if (p.markCenter) {
                const polyline = lineString(p.coordinates);
                const centerPoint = turf.along(polyline, turf.length(polyline) / 2)
                lLabels.push(this.buildLabel([
                        centerPoint.geometry.coordinates[0],
                        centerPoint.geometry.coordinates[1]
                    ], {
                        icon: p.icon,
                        color: p.color,
                        iconColor: p.iconColor,
                        width: 20,
                        height: 18,
                        events: {
                            ...options?.noClick ? {} : { "click": () => lPolyline.fire("click") },
                            ...options?.noHover ? {} : { "mouseover": () => lPolyline.fire("mouseover") },
                            ...options?.noHover ? {} : { "mouseout": () => lPolyline.fire("mouseout") }
                        }
                    })
                )
            }
            //this.mapObjectData.push({ layer: lPolyline, data: p.data });
        });
        this.addLayers(lPolylines, layerId, { ...options?.parentLayerId ? { parentLayerId: options.parentLayerId } : {} });
        polylines.forEach((p, i) => {
            if (p.markCenter) {
                const polyline = lineString(p.coordinates);
                const lPolyline = lPolylines[i];
                const centerPoint = turf.along(polyline, turf.length(polyline) / 2)
                lLabels.push(this.buildLabel([
                    centerPoint.geometry.coordinates[0],
                    centerPoint.geometry.coordinates[1]
                    ], {
                        icon: p.icon,
                        color: p.color,
                        iconColor: p.iconColor,
                        width: 20,
                        height: 18,
                        events: {
                            ...options?.noClick ? {} : { "click": () => lPolyline.fire("click") },
                            ...options?.noHover ? {} : { "mouseover": () => lPolyline.fire("mouseover") },
                            ...options?.noHover ? {} : { "mouseout": () => lPolyline.fire("mouseout") }
                        }
                    })
                )
            }
        });
        this.addLayers(lLabels, layerId, { unclustered: true, ...options?.parentLayerId ? { parentLayerId: options.parentLayerId } : {} });
        return lPolylines;
    }

    public getLayerData(layer: L.Layer) {
        return this.mapObjectData.find(o => o.layer === layer)?.data;
    }

    /**
     * Builds center markers for the provided polylines.
     *
     * @param lPolylines Array of Leaflet polylines.
     * @returns Array of built center markers.
     */
    public buildCenterMarkers(lPolylines: L.Polyline[]) {
        const lMarkers: L.Marker[] = [] as L.Marker[];
        lPolylines.forEach(p => {
            lMarkers.push(this.buildMarkerLegacy(
                {
                    type: MAP_TEMPLATE.MARKER,
                    coordinates: [p.getCenter().lat, p.getCenter().lng],
                } as i_marker,
                [10,10]
                )
            );
        });
        return lMarkers;
    }

    /**
     * Builds a Leaflet marker based on the provided marker data.
     *
     * @param marker Marker data object.
     * @param size (Optional) Size of the marker icon.
     * @returns The built Leaflet marker.
     */
    private buildMarkerLegacy(marker: i_marker, size?: [number, number]) {
        if (size === undefined) {
            size = [15,15];
        }
        // create icon for marker
        const markerIconComponent = createApp(ZmMarkerIcon, {
            icon: marker.icon,
            iconColor: marker.iconColor,
            color: marker.color
        });
        markerIconComponent.use(vuetify)
        const container = document.createElement('div');
        const mountedMarkerIconComponent= markerIconComponent.mount(container);
        const lOptions: L.MarkerOptions = {
            icon: L.divIcon({
                html: mountedMarkerIconComponent.$el,
                className: 'noWhiteBox',
                iconAnchor: size
            })
        };
        const lMarker = new L.Marker(
            marker.coordinates,
            lOptions
        );
        lMarker.on("click", () => {
            useStore().set(SID.DRAWER_STATE, true);
            useStore().set(SID.CONTEXT_STATE, true);
            this.setContextAndRefreshIntervalLegacy(marker, {
                lat: lMarker.getLatLng().lat,
                lng: lMarker.getLatLng().lng
            }, LID.CONTEXT_DATA);
        });
        lMarker.on("mouseover", () => {
            if (this.currentPopup === undefined) {
                this.showPopup(lMarker.getLatLng());
                this.setContextAndRefreshIntervalLegacy(marker, {
                    lat: lMarker.getLatLng().lat,
                    lng: lMarker.getLatLng().lng
                }, LID.CONTEXT_DATA_POPUP);
            }
        });
        lMarker.on("mouseout", () => {
            this.closePopup();
            usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
        });
        return lMarker;
    }

    /**
     * Builds a Leaflet polyline based on the provided polyline data.
     *
     * @param polyline Polyline data object.
     * @returns The built Leaflet polyline.
     */
    private buildPolylineLegacy(polyline: i_polyline) {
        const lPolyline = new L.Polyline(
            polyline.coordinates,
            {
                color: polyline.color,
                weight: 3,
                renderer: this.renderer
            }
        )
        lPolyline.on("click", (event) => {
            useStore().set(SID.DRAWER_STATE, true);
            useStore().set(SID.CONTEXT_STATE, true);
            this.setContextAndRefreshIntervalLegacy(polyline, {
                lat: lPolyline.getCenter().lat,
                lng: lPolyline.getCenter().lng
            }, LID.CONTEXT_DATA);
            L.DomEvent.stopPropagation(event);
        });
        lPolyline.on("mouseover", () => {
            if (this.currentPopup === undefined) {
                this.showPopup(lPolyline.getCenter());
                this.setContextAndRefreshIntervalLegacy(polyline, {
                    lat: lPolyline.getCenter().lat,
                    lng: lPolyline.getCenter().lng
                }, LID.CONTEXT_DATA_POPUP);
            }
        });
        lPolyline.on("mouseout", () => {
            this.closePopup();
            usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
        });
        return lPolyline;
    }

    /**
     * Builds a Leaflet marker based on the provided marker data.
     *
     * @param marker Marker data object.
     * @param options (Optional) Build options
     * @returns The built Leaflet marker.
     */
    private buildMarker(marker: IMarker, options?: IBuildMarkerOptions) {
        const size = options?.size ?? [15,15];
        // create icon for marker
        const markerIconComponent = createApp(ZmMarkerIcon, {
            icon: marker.icon,
            iconColor: marker.iconColor,
            color: marker.color
        });
        markerIconComponent.use(vuetify)
        const container = document.createElement('div');
        const mountedMarkerIconComponent= markerIconComponent.mount(container);
        const lOptions: L.MarkerOptions = {
            icon: L.divIcon({
                html: mountedMarkerIconComponent.$el,
                className: 'noWhiteBox',
                iconAnchor: size
            })
        };
        const lMarker = new L.Marker(
            marker.coordinates,
            lOptions
        );
        if (!options?.noClick) {
            lMarker.on("click", () => {
                useStore().set(SID.DRAWER_STATE, true);
                useStore().set(SID.CONTEXT_STATE, true);
                this.setContextAndRefreshInterval(marker, {
                    lat: lMarker.getLatLng().lat,
                    lng: lMarker.getLatLng().lng
                }, LID.CONTEXT_DATA);
            });
        }
        if (!options?.noHover) {
            lMarker.on("mouseover", () => {
                if (this.currentPopup === undefined) {
                    this.showPopup(lMarker.getLatLng());
                    this.setContextAndRefreshInterval(marker, {
                        lat: lMarker.getLatLng().lat,
                        lng: lMarker.getLatLng().lng
                    }, LID.CONTEXT_DATA_POPUP);
                }
            });
            lMarker.on("mouseout", () => {
                this.closePopup();
                usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
            });
        }
        return lMarker;
    }

    /**
     * Builds a Leaflet polyline based on the provided polyline data.
     *
     * @param polyline Polyline data object.
     * @param options (Optional) Build options.
     * @returns The built Leaflet polyline.
     */
    private buildPolyline(polyline: IPolyline, options?: IBuildLayerOptions) {
        const lPolyline = new L.Polyline(
            polyline.coordinates,
            {
                color: polyline.color,
                weight: 3,
                renderer: this.renderer
            }
        )
        if (!options?.noClick) {
            lPolyline.on("click", (event) => {
                useStore().set(SID.DRAWER_STATE, true);
                useStore().set(SID.CONTEXT_STATE, true);
                this.setContextAndRefreshInterval(polyline, {
                    lat: lPolyline.getCenter().lat,
                    lng: lPolyline.getCenter().lng
                }, LID.CONTEXT_DATA);
                L.DomEvent.stopPropagation(event);
            });
        }
        if (!options?.noHover) {
            lPolyline.on("mouseover", () => {
                if (this.currentPopup === undefined) {
                    this.showPopup(lPolyline.getCenter());
                    this.setContextAndRefreshInterval(polyline, {
                        lat: lPolyline.getCenter().lat,
                        lng: lPolyline.getCenter().lng
                    }, LID.CONTEXT_DATA_POPUP);
                }
            });
            lPolyline.on("mouseout", () => {
                this.closePopup();
                usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
            });
        }
        return lPolyline;
    }

    /**
     * Builds a Leaflet polygon based on the provided polygon data.
     *
     * @param polygon Polygon data object.
     * @param options (Optional) Build options.
     * @returns The built Leaflet polygon.
     */
    private buildPolygon(polygon: IPolygon, options?: IBuildPolygonOptions) {
        const lPolygon = new L.Polygon(
            polygon.coordinates,
            {
                color: polygon.color,
                weight: 3,
                renderer: this.renderer
            }
        );
        if (!options?.noClick) {
            lPolygon.on("click", (event) => {
                useStore().set(SID.DRAWER_STATE, true);
                useStore().set(SID.CONTEXT_STATE, true);
                this.setContextAndRefreshInterval(polygon, {
                    lat: lPolygon.getBounds().getCenter().lat,
                    lng: lPolygon.getBounds().getCenter().lng
                }, LID.CONTEXT_DATA);
                L.DomEvent.stopPropagation(event);
            });
        }
        if (!options?.noHover) {
            lPolygon.on("mouseover", () => {
                if (this.currentPopup === undefined) {
                    this.showPopup(lPolygon.getCenter());
                    this.setContextAndRefreshInterval(polygon, {
                        lat: lPolygon.getBounds().getCenter().lat,
                        lng: lPolygon.getBounds().getCenter().lng
                    }, LID.CONTEXT_DATA_POPUP);
                }
            });
            lPolygon.on("mouseout", () => {
                this.closePopup();
                usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
            });
        }
        return lPolygon;
    }

    /**
     * Wrapper for context creation and update interval setup.
     *
     * @param mapObject
     * @param position
     * @param lid
     * @private
     */
    private setContextAndRefreshIntervalLegacy(mapObject: i_mapObject, position: i_position, lid: LID.CONTEXT_DATA | LID.CONTEXT_DATA_POPUP) {
        const lidPoll = lid === LID.CONTEXT_DATA ? LID.CONTEXT_POLL_ID : LID.CONTEXT_POPUP_POLL_ID;
        this.setContextForMapObjectLegacy(mapObject, position, lid);
        if (usePolling().isPolling(lidPoll)) {
            usePolling().stopPoll(lidPoll);
        }
        usePolling().addPoll(
            lidPoll,
            () => {
                this.setContextForMapObjectLegacy(mapObject, position, lid)
            },
            mapObject.context.refreshRate && !isNaN(mapObject.context.refreshRate) ? mapObject.context.refreshRate * 1000 : 60000
        );
    }

    /**
     * Wrapper for context creation and update interval setup.
     *
     * @param mapObject
     * @param position
     * @param lid
     * @private
     */
    private setContextAndRefreshInterval(mapObject: TMapObject, position: IPosition, lid: LID.CONTEXT_DATA | LID.CONTEXT_DATA_POPUP) {
        const lidPoll = lid === LID.CONTEXT_DATA ? LID.CONTEXT_POLL_ID : LID.CONTEXT_POPUP_POLL_ID;
        this.setContextForMapObject(mapObject, position, lid);
        if (usePolling().isPolling(lidPoll)) {
            usePolling().stopPoll(lidPoll);
        }
        usePolling().addPoll(
            lidPoll,
            () => {
                this.setContextForMapObject(mapObject, position, lid)
            },
            mapObject.context.request.refreshRate && !isNaN(mapObject.context.request.refreshRate) ? mapObject.context.request.refreshRate * 1000 : 60000
        );
    }

    /**
     * Adds a single layer to the corresponding layer group.
     *
     * @param layerElement Layer element to be added.
     * @param layerId Unique identifier for the layer group.
     * @param options Options
     */
    public addLayer(layerElement: L.Layer, layerId: string, options?: ILayerOptions) {
        this.addLayers([layerElement], layerId, options);
    }

    /**
     * Adds the provided layer elements to the cluster layer of the map instance.
     *
     * @param layerElements Array of layer elements to be added to the cluster layer.
     * @param layerGroupId Unique identifier for the layer group.
     * @param options Options: unclustered [boolean] prevents element clustering on zoom out, parentLayerId [string] assigns a parent layer - on removal of the parent the child is also removed)
     */
    public addLayers(layerElements: L.Layer[], layerGroupId: string, options?: ILayerOptions) {
        this.log.debug(`Add elements with id ${layerGroupId} to cluster layer of mapInstance.`)
        if (options?.parentLayerId !== undefined && this.layerGroups[options.parentLayerId] == undefined) {
            this.log.error(`No active parent layer was found for id '${options?.parentLayerId}'!`);
        } else if (options?.parentLayerId !== undefined) {
            if (!Array.isArray(this.childLayersOfLayer[options.parentLayerId]) || !this.childLayersOfLayer[options.parentLayerId].includes(layerGroupId)) {
                this.childLayersOfLayer[options.parentLayerId] = [...Array.isArray(this.childLayersOfLayer[options.parentLayerId]) ? this.childLayersOfLayer[options.parentLayerId] : [], layerGroupId];
            }
        }
        if (this.layerGroups[layerGroupId] == undefined) {
            this.layerGroups[layerGroupId] = L.layerGroup([]);
        }
        this.layerGroups[layerGroupId] = L.layerGroup([
            ...this.layerGroups[layerGroupId].getLayers(),
            ...layerElements
        ])
        if (options?.unclustered) {
            layerElements.forEach(l => {
                this.mapInstance?.addLayer(l);
            })
        } else {
            layerElements.forEach(l => {
                this.clusterGroup.addLayer(l);
            })
        }
    }

    /**
     * Gets the layer elements from the layer group with the provided id. If no layer group with the given ID exists
     * an empty array is returned.
     *
     * @param layerId The id of the group to query for
     */
    public getLayerGroup(layerId: string) {
        if (this.layerGroups[layerId] !== undefined) {
            return this.layerGroups[layerId].getLayers() as (L.Marker | L.Polyline | L.Polygon | L.GeoJSON)[];
        } else {
            return [];
        }
    }

    /**
     * Sets the provided layer elements to the map instance.
     *
     * @param layerElements Array of layer elements to be added to the map instance.
     * @param layerGroupId Unique identifier for the layer group.
     */
    public setLayersForGroup(layerElements: L.Layer[], layerGroupId: string) {
        this.layerGroups[layerGroupId] = L.layerGroup(layerElements);
        this.layerGroups[layerGroupId].getLayers().forEach(l => {
            this.mapInstance?.addLayer(l);
        })
    }

    /**
     * Removes the layer group with the specified identifier from the map instance and cluster layer.
     *
     * @param layerId Unique identifier for the layer group to be removed.
     * @returns True if the layer group was successfully removed, otherwise false.
     */
    public removeLayer(layerId: string) {
        if (this.layerGroups[layerId] !== undefined) {
            this.log.debug(`Removed ${this.layerGroups[layerId].getLayers().length} layers`);
            // remove all child layers
            if (Array.isArray(this.childLayersOfLayer[layerId])) {
                this.log.debug(`Remove child layers of ${layerId}!`);
                for (const childLayerId of this.childLayersOfLayer[layerId]) {
                    this.removeLayer(childLayerId);
                }
            }
            delete this.childLayersOfLayer[layerId];
            this.clusterGroup.removeLayers(this.layerGroups[layerId].getLayers());
            this.layerGroups[layerId].getLayers().forEach(l => {
                this.mapObjectData = this.mapObjectData.filter(o => o.layer !== l);
                this.mapInstance?.removeLayer(l);
            })
            return true;
        } else {
            return false;
        }
    }

    public setCurrentLocationMarker(position: i_position) {
        const marker: i_marker = {
            id: `zm-my-position-marker`,
            type: MAP_TEMPLATE.MARKER,
            name: {
                en: "Current Position",
                de: "Aktuelle Position"
            },
            icon: "mdi-crosshairs",
            iconColor: "white",
            color: "#00bdd2",
            coordinates: [position.lat, position.lng],
            data: {},
            context: {
                request: {
                    url: useGeocoder().buildByPositionUrl(position).toString(),
                    dataRoot: ""
                },
                formatting: {
                    type: CONTEXT_TYPES.TEXT,
                    value: {
                        en: "<b>Location:</b> {{ZM_REF[address.road]}} {{ZM_REF[address.house_number,]}}<br><b>Coordinate:</b> {{ZM_ROUND[{{ZM_REF[lat]}}]}}, {{ZM_ROUND[{{ZM_REF[lon]}}]}}",
                        de: "<b>Ort:</b> {{ZM_REF[address.road]}} {{ZM_REF[address.house_number,]}}<br><b>Koordinate:</b> {{ZM_ROUND[{{ZM_REF[lat]}}]}}, {{ZM_ROUND[{{ZM_REF[lon]}}]}}"
                    }
                }
            }
        }
        if (this.layerGroups[LID.MY_POSITION_MARKER] !== undefined) {
            if (this.layerGroups[LID.MY_POSITION_MARKER].getLayers().length > 0 && this.layerGroups[LID.MY_POSITION_MARKER].getLayers()[0] instanceof L.Marker) {
                const currentLocationMarker = this.layerGroups[LID.MY_POSITION_MARKER].getLayers()[0] as L.Marker;
                if (currentLocationMarker.getLatLng().lat !== marker.coordinates[0] || currentLocationMarker.getLatLng().lng !== marker.coordinates[1]) {
                    this.removeLayer(LID.MY_POSITION_MARKER);
                    const markers = this.buildMarkersLegacy([marker]);
                    this.setLayersForGroup(markers, LID.MY_POSITION_MARKER);
                }
            }
        } else {
            const markers = this.buildMarkersLegacy([marker]);
            this.setLayersForGroup(markers, LID.MY_POSITION_MARKER);
        }
    }

    /**
     * Adds a router marker for the specified point of interest (POI) to the map.
     *
     * @param poi The point of interest (POI) for which to add the router marker.
     * @param idx The index of the router marker.
     * @param max_idx The maximum index of the router marker.
     */
    public addRouterMarker(poi: i_poi, idx: number, max_idx: number) {
        if (poi.location !== undefined) {
            const marker: i_marker = {
                id: `routing-marker-${idx}`,
                type: MAP_TEMPLATE.MARKER,
                name: poi.name,
                icon: this.getRouterMarkerIconByIndex(idx, max_idx),
                iconColor: "white",
                color: "#006099",
                coordinates: [poi.location.lat, poi.location.lng],
                data: {},
                context: {
                    formatting: {
                        type: CONTEXT_TYPES.TEXT,
                        value: poi.name
                    }
                }
            }
            this.removeLayer(LID.ROUTER_WAYPOINT_MARKER_PREFIX + idx);
            const markers = this.buildMarkersLegacy([marker]);
            this.setLayersForGroup(markers, LID.ROUTER_WAYPOINT_MARKER_PREFIX + idx);
        }
    }

    /**
     * Gets the router marker icon based on the index and maximum index.
     *
     * @param idx The index of the router marker.
     * @param max_idx The maximum index of the router marker.
     * @returns The icon for the router marker.
     */
    public getRouterMarkerIconByIndex(idx: number, max_idx: number) {
        let icon = MDI.MARKER_START;
        if (idx != 0 && idx == max_idx) {
            // final destination x_x (flag)
            icon = MDI.MARKER_FLAG;
        } else if (idx != 0) {
            this.log.debug("set waypoint marker for idx " + idx + " of " + max_idx)
            icon = MDI.MARKER_VIA;
        }
        return icon;
    }

    /**
     * Show the popup at a specific position.
     *
     * @param position The position on the map where the popup will be created.
     */
    private showPopup(position: L.LatLng) {
        if (this.mapInstance !== undefined) {
            this.currentPopup = L.popup()
                .setLatLng(position);
            const popupComponent = createApp(ZmMapobjectContext, {
                popupMode: true
            });
            popupComponent.use(vuetify)
            const container = document.createElement('div');
            const mountedPopup= popupComponent.mount(container);
            this.currentPopup.setContent(mountedPopup.$el).openOn(this.mapInstance);
        }
    }

    /**
     * Closes the current popup.
     */
    private closePopup() {
        if (this.currentPopup) {
            this.currentPopup.close();
            this.currentPopup = undefined;
        }
    }

    /**
     * Provides store with contextual information of a map object.
     *
     * @param mapObject The map object for which to display context information.
     * @param position (deprecated) The position on the map where the context will be shown.
     * @param lid The LID of either the context of the sidebar or the popup
     */
    private setContextForMapObjectLegacy(mapObject: i_mapObject, position: i_position, lid: LID.CONTEXT_DATA | LID.CONTEXT_DATA_POPUP) {
        if (mapObject.context.request !== undefined) {
            // show loading if the current data is not of the same map object
            useStore().set(lid, {
                id: mapObject.id,
                title: mapObject.name,
                icon: mapObject.icon,
                color: mapObject.color
            } as i_contextScreenData);
            if (this.log.willDebug()) {
                this.log.debug(`fetching data for this mapObject: ${JSON.stringify(mapObject)}`);
            }
            useFetchWorker().getContextForMapObjectLegacy(mapObject)
                .then(result =>  {
                    if (this.log.willDebug()) {
                        this.log.debug("Fetched context for '" + mapObject.name + "': " + JSON.stringify(result));
                    }
                    const contextData = {
                        id: mapObject.id,
                        title: mapObject.name,
                        icon: mapObject.icon,
                        color: mapObject.color,
                        context: result,
                        position: position
                    } as i_contextScreenData;
                    if (JSON.stringify(useStore().get(lid, {})) !== JSON.stringify(contextData)) {
                        useStore().set(lid, contextData);
                    }
                })
                .catch(error => {
                    this.log.error("Error during context fetching: " + error)
                })
        } else {
            const staticContext: i_context = {
                type: mapObject.context.formatting.type,
                value: mapObject.context.formatting.value
            };
            useStore().set(lid, {
                id: mapObject.id,
                title: mapObject.name,
                icon: mapObject.icon,
                color: mapObject.color,
                context: staticContext,
                position: position
            } as i_contextScreenData);
        }
    }

    /**
     * Provides store with contextual information of a map object.
     *
     * @param mapObject The map object for which to display context information.
     * @param position (deprecated) The position on the map where the context will be shown.
     * @param lid The LID of either the context of the sidebar or the popup
     */
    private setContextForMapObject(mapObject: IMapObject, position: IPosition, lid: LID.CONTEXT_DATA | LID.CONTEXT_DATA_POPUP) {
        if (mapObject.context.request !== undefined) {
            // show loading if the current data is not of the same map object
            useStore().set(lid, {
                id: mapObject.id,
                title: mapObject.name,
                icon: mapObject.icon,
                color: mapObject.color
            } as i_contextScreenData);
            if (this.log.willDebug()) {
                this.log.debug(`fetching data for this mapObject: ${JSON.stringify(mapObject)}`);
            }
            useFetchWorker().getContextForMapObject(mapObject)
                .then(result =>  {
                    if (this.log.willDebug()) {
                        this.log.debug("Fetched context for '" + mapObject.name + "': " + JSON.stringify(result));
                    }
                    const contextData = {
                        id: mapObject.id,
                        title: mapObject.name,
                        icon: mapObject.icon,
                        color: mapObject.color,
                        context: result,
                        position: position
                    } as IContextScreenData;
                    if (JSON.stringify(useStore().get(lid, {})) !== JSON.stringify(contextData)) {
                        useStore().set(lid, contextData);
                    }
                })
                .catch(error => {
                    this.log.error("Error during context fetching: " + error)
                })
        } else {
            const staticContext: TFormatting = mapObject.context.formatting;
            useStore().set(lid, {
                id: mapObject.id,
                title: mapObject.name,
                icon: mapObject.icon,
                color: mapObject.color,
                context: staticContext,
                position: position
            } as IContextScreenData);
        }
    }

    /**
     * Draws a polyline on the map with the specified color and optional label.
     *
     * @param polyline Array of latitude and longitude coordinates defining the polyline.
     * @param layerId Unique identifier for the polyline layer.
     * @param color Color of the polyline.
     * @param label (Optional) Label configuration object for the polyline.
     * @returns The drawn polyline.
     */
    public drawPolyline(polyline: [number, number][], layerId: string, color: string, label?: i_label) {
        this.log.debug(`draw polyline on layer ${layerId}`);
        const line = L.polyline(polyline, { color: color });
        if (this.polylines[layerId] !== undefined) {
            this.mapInstance?.removeLayer(this.polylines[layerId]);
        }
        this.polylines[layerId] = line;
        this.mapInstance?.addLayer(this.polylines[layerId]);
        if (label !== undefined) {
            label.color = label.color == undefined ? color : label.color;
            const lLabel = this.buildLabel([line.getCenter().lat, line.getCenter().lng], label);
            this.setLayersForGroup([lLabel], layerId + ":label");
        }
        return line;
    }

    /**
     * Builds a label marker on the map at the specified position with the provided configuration.
     *
     * @param position Array of latitude and longitude coordinates for the label marker position.
     * @param options Options like text content, label icon, coloration etc.
     * @returns The built label marker.
     */
    public buildLabel(position: [number, number], options?: IBuildLabelOptions) {
        const labelMarkerComponent = createApp(ZmMapLabel, {
            label: options?.content ? new Text(options?.content) : undefined,
            textColor: options?.iconColor,
            color: options?.color ?? "primary",
            icon: options?.icon
        });
        labelMarkerComponent.use(vuetify)
        const container = document.createElement('div');
        //container.id = layerId;
        const mountedLabelMarkerComponent= labelMarkerComponent.mount(container);
        const markerOptions: L.MarkerOptions = {
            icon: L.divIcon({
                html: mountedLabelMarkerComponent.$el,
                className: 'noWhiteBox',
                iconSize: [
                    options?.width ?? 70,
                    options?.height ?? 35
                ]
            })
        };
        const lLabel = new L.Marker(new L.LatLng(position[0], position[1]), markerOptions);
        const events = options?.events;
        if (events) {
            for (const key of Object.keys(events)) {
                lLabel.on(key, (event) => {
                    if (events[key] !== undefined) {
                        events[key]();
                        L.DomEvent.stopPropagation(event);
                    }
                })
            }
        }
        return lLabel;
    }

    /**
     * Draws a route on the map based on the provided route data.
     * Removes any existing route before drawing the new route.
     *
     * @param route The route data containing segments to be drawn.
     */
    public drawRoute(route: i_route) {
        this.removeRoute();
        let bounds: L.LatLngBounds | undefined;
        route.segments.forEach((s: i_routeSegment, is: number) => {
            const polyline = this.drawPolyline(
                s.polyline,
                LID.CURRENT_ROUTE + "-" + is,
                motColors[s.mode],
                {
                    name: StringUtils.fromDuration(s.duration),
                    icon: motIcons[s.mode],
                    width: Math.floor(s.duration/(60*60*1000)) > 0 ? 90 : 75,
                } as i_label
            );
            if (bounds === undefined) {
                bounds = polyline.getBounds();
            } else {
                bounds.extend(polyline.getBounds());
            }
        });
        if (bounds !== undefined) {
            this.mapInstance?.fitBounds(bounds, {padding: [50,50]});
        }
    }

    /**
     * Removes the current route and its associated elements from the map.
     */
    public removeRoute() {
        let i = 0;
        let routeSegmentsAreLeft = true;
        do {
            if (this.polylines[LID.CURRENT_ROUTE + "-" + i] !== undefined) {
                this.polylines[LID.CURRENT_ROUTE + "-" + i].remove();
                this.labels[LID.CURRENT_ROUTE + "-" + i + ":label"].remove();
                i += 1;
            } else {
                routeSegmentsAreLeft = false;
            }
        } while (routeSegmentsAreLeft);
    }

    /**
     * Removes all label markers from the map.
     */
    public removeAllLabels() {
        Object.values(this.labels).forEach(p => {
            p.remove();
        });
        this.labels = {};
    }

    /**
     * Invalidates the map size, forcing it to recalculate its size.
     * Useful when the map container size changes dynamically.
     */
    public rerenderMap() {
        this.mapInstance?.invalidateSize();
    }

    /**
     * Changes the container of the map to the one specified by ID.
     *
     * @param newContainerId The ID of the new container element.
     */
    public rebindToContainerById(newContainerId: string, newTileUrl: string) {
        const newContainer = document.getElementById(newContainerId);
        if (newContainer && this.mapInstance) {
            this.mapInstance.getContainer().parentNode?.removeChild(this.mapInstance.getContainer()); // Remove map from old container
            newContainer.appendChild(this.mapInstance.getContainer()); // Append map to new container
            this.changeTileUrl(newTileUrl);
            this.rerenderMap(); // Update map size
        } else {
            this.log.error('Container not found');
        }
    }

    /**
     * Changes the current url providing the leaflet tiles.
     *
     * @param newTileUrl is the url to the tile server that should be used
     */
    public changeTileUrl(newTileUrl: string) {
        if (this.mapInstance) {
            this.log.debug("Refresh Tile URL!");
            this.mapInstance.eachLayer((l) => {
                if (l instanceof L.TileLayer) {
                    this.log.debug("Remove old tile layer!");
                    this.mapInstance?.removeLayer(l);
                }
            });
            this.log.debug("Add new tile layer!");
            this.mapInstance.addLayer(L.tileLayer(newTileUrl, {
                maxZoom: 19,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }));
        }
    }

    /**
     * Returns the leaflet map instance.
     *
     * @returns Map or undefined
     */
    public getInstance() {
        return this.mapInstance;
    }
}

const mapService = new MapService();

export const useMap = (): MapService => {
    return mapService;
}
