export enum ITEM {
    HEADER = "zm-dialog-header",
    DESC = "zm-dialog-desc",
    INPUT = "zm-dialog-input",
    TEXT_AREA = "zm-dialog-text-area",
    JSON_AREA = "zm-dialog-json-area",
    BUTTON = "zm-dialog-button",
    CONTAINER = "zm-dialog-container",
    STEPPER = "zm-dialog-stepper",
    TABS = "zm-dialog-tabs",
    SELECT = "zm-select",
    ICON_PICKER = "zm-icon-picker",
    COLOR_PICKER = "zm-color-picker",
    JSON_PICKER = "zm-json-picker",
    BUTTON_PREVIEW = "zm-button-preview",
    MARKER_PREVIEW = "zm-marker-preview",
    CONTEXT_PREVIEW = "zm-context-preview",
    CHECKBOX = "zm-checkbox",
    TEXT_EDITOR = "zm-text-editor",
    DIVIDER = "zm-divider",
    FILE_INPUT = "zm-file-input",
    PROGRESS_STEPS = "zm-progress-steps",
    ITERATOR = "zm-iterator",
    DATA_ENTRY_PREVIEW = "zm-data-entry-preview",
    // TODO: not implemented yet
    POLYLINE_PREVIEW = "zm-polyline-preview"
}

export enum PROGRESS_STATES {
    HIDDEN = "zm-progress-states-hidden",
    PENDING = "zm-progress-states-pending",
    SUCCESS = "zm-progress-states-success",
    FAILURE = "zm-progress-states-failure",
    DISABLED = "zm-progress-states-disabled",
    ALERT = "zm-progress-states-alert"
}
