import {Base} from "@/Base";
import {useFetchWorker} from "@/services/fetch.worker.service";
import {isError} from "@/types/typeguards/frontend.guards";
import {i_response} from "@/types/interfaces/frontend.interfaces";

const GEOJSON_BASE_URL = "/api/v2/geojson"

class GeojsonService extends Base {
    private backendBaseUrl: string | undefined;
    constructor() {
        super(GeojsonService.name);
    }

    public init() {
        this.get(`/baseurl`)
            .then((res) => {
                this.backendBaseUrl = res.data.baseurl;
            })
            .catch((error) => {
                this.log.error(`Cannot access backend geo json service to retrieve base url: ${error.message}`);
            })
    }

    private async get(path: string, query?: { [key: string]: number | string }) {
        return useFetchWorker().axiosRequest({
            baseURL: GEOJSON_BASE_URL,
            url: path,
            params: query,
            method: "GET",
            timeout: 45000
        }, { auth: true }).then(response => {
            if (isError(response)) {
                throw new Error(response.message)
            } else {
                return response as i_response;
            }
        })
    }

    private async post(path: string, data: any, query?: { [key: string]: number | string }) {
        return useFetchWorker().axiosRequest({
            baseURL: GEOJSON_BASE_URL,
            url: path,
            params: query,
            method: "POST",
            timeout: 45000,
            data
        }, { auth: true }).then(response => {
            if (isError(response)) {
                throw new Error(response.message)
            } else {
                return response as i_response;
            }
        })
    }

    private async delete(path: string) {
        return useFetchWorker().axiosRequest({
            baseURL: GEOJSON_BASE_URL,
            url: path,
            method: "DELETE"
        }, { auth: true }).then(response => {
            if (isError(response)) {
                throw new Error(response.message)
            } else {
                return response as i_response;
            }
        })
    }

    // Fetch all collections
    public async getCollections() {
        return this.get("/collections") as Promise<{status: number, data: { name: string, documents: number, crs: string }[]}>;
    }

    // Upload a file (uses FormData)
    public async uploadFile(file: File) {
        const formData = new FormData();
        formData.set("file", file, file.name);

        return useFetchWorker().axiosRequest({
            baseURL: GEOJSON_BASE_URL,
            url: "/upload",
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 45000,
            data: formData,
        }, { auth: true }).then(response => {
            if (isError(response)) {
                throw new Error(response.message)
            } else {
                return response as i_response;
            }
        })
    }

    // List all uploaded files
    public async getUploads() {
        return this.get("/uploads");
    }

    // Add data to a collection
    public async addDataToCollection(data: {
        name: string;
        downloadUrl?: string;
        uploadId?: string;
        path?: string;
    }) {
        return this.post("/collection", data) as Promise<{ status: number, data: { collection: string, message: string }}>;
    }

    public getCollectionFeaturesUrl(collectionName: string, params?: Record<string, any>) {
        return `${this.backendBaseUrl}/api/collection/${collectionName}/features${params ? '?' + Object.keys(params).map(k => k + '=' + params[k]).join("&") : ''}`;
    }

    // Retrieve a specific collection's feature collection
    public async getFeatureCollection(collectionName: string, params: Record<string, any> = {}) {
        return this.get(`/collection/${collectionName}`, { limit: 50 });
    }

    // Get a specific feature by ID within a collection
    public async getFeatureById(collectionName: string, featureId: string) {
        return this.get(`/collection/${collectionName}/features/${featureId}`);
    }

    // Get all features from a collection
    public async getAllFeatures(collectionName: string, params: Record<string, any> = {}) {
        return this.get(`/collection/${collectionName}/features`, { limit: 50 });
    }

    // Get locations from a collection
    public async getLocations(collectionName: string, params: Record<string, any> = {}) {
        return this.get(`/collection/${collectionName}/locations`, { limit: 50 });
    }

    // Delete a collection
    public async deleteCollection(collectionName: string) {
        return this.delete(`/collection/${collectionName}`);
    }
}

const geojsonService = new GeojsonService();

export const useGeoJSON = () => {
    return geojsonService;
}
